import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Form, Button, Input, Switcher } from "../../../../ui";
import { AddIcon, CloseIcon, CalendarLightIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createPanelDiscussion,
  updatePanelDiscussion,
} from "../../../../../store";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const PanelDiscussions = ({ userId, editData }) => {
  const [doCreatePanelDiscussion] = useThunk(createPanelDiscussion);
  const [doUpdatePanelDiscussion] = useThunk(updatePanelDiscussion);
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();
  const [discussion, setDiscussion] = useState({});
  const [topic, setTopic] = useState("");
  const [panelist, setPanelist] = useState("");

  useEffect(() => {
    if (editData) {
      setDiscussion({
        title: editData?.title,
        location: editData?.location,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        panelists: editData?.panelists.map((panelist) => panelist?.name),
        topics: editData?.topics.map((topic) => topic?.title),
      });
    }
  }, [editData]);

  const handleLocation = (_, value) => {
    if (value) {
      handleChange("location", "Online");
    } else {
      discussion?.location && handleChange("location", "");
    }
  };

  const handleTopics = (e, operation, value = "") => {
    e.preventDefault();

    let topics = discussion?.topics || [];

    if (operation === "add" && !!topic) {
      topics = [...topics, topic];
      handleChange("topics", topics);
      setTopic("");
      return;
    }

    if (operation === "remove") {
      topics = topics?.filter((topic) => topic !== value);
      handleChange("topics", topics);
      return;
    }
  };

  const handlePanelists = (e, operation, value = "") => {
    e.preventDefault();

    let panelists = discussion?.panelists || [];

    if (operation === "add" && !!panelist) {
      panelists = [...panelists, panelist];
      handleChange("panelists", panelists);
      setPanelist("");
      return;
    }

    if (operation === "remove") {
      panelists = panelists?.filter((panelist) => panelist !== value);
      handleChange("panelists", panelists);
      return;
    }
  };

  const handleChange = (name, value) => {
    setDiscussion({ ...discussion, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!discussion.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!discussion.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!discussion.endDate || !discussion.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }
    doCreatePanelDiscussion({
      title: discussion?.title,
      location: discussion?.location,
      start: discussion?.startDate + " 00:00:00",
      end: discussion?.endDate + " 00:00:00",
      topics: discussion?.topics?.map((topic) => ({ title: topic })),
      panelists: discussion?.panelists?.map((panelist) => ({ name: panelist })),
      user_id: userId,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!discussion.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!discussion.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!discussion.endDate || !discussion.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdatePanelDiscussion({
      id: editData?.id,
      data: {
        title: discussion?.title,
        location: discussion?.location,
        start: discussion?.startDate + " 00:00:00",
        end: discussion?.endDate + " 00:00:00",
        topics: discussion?.topics?.map((topic) => ({ title: topic })),
        panelists: discussion?.panelists?.map((panelist) => ({
          name: panelist,
        })),
        user_id: userId,
      },
    });
  };

  return (
    <div className="panel-discussions">
      <Form>
        <Input
          name="title"
          placeholder={defaultPlaceholder}
          label="Panel Title"
          value={discussion?.title}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Location</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Online</label>
              <Switcher
                checked={discussion?.location === "Online"}
                handleChange={handleLocation}
              />
            </div>
          </div>
          <Input
            name="location"
            placeholder={defaultPlaceholder}
            value={discussion?.location}
            disabled={discussion?.location === "Online"}
            handleChange={(e) =>
              handleChange(e?.target?.name, e?.target?.value)
            }
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <div className="form-row">
            <div className="form-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(discussion?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarLightIcon />
              </div>
            </div>
            <div className="form-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(discussion?.endDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarLightIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Topics"
              placeholder={defaultPlaceholder}
              value={topic}
              handleChange={(e) => setTopic(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handleTopics(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {discussion?.topics?.length > 0 && (
            <ul className="topics-list">
              {discussion?.topics?.map((topic, index) => (
                <li key={`topic-${index}`}>
                  <p>{topic}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => handleTopics(e, "remove", topic)}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Panelists"
              placeholder={defaultPlaceholder}
              value={panelist}
              handleChange={(e) => setPanelist(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handlePanelists(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {discussion?.panelists?.length > 0 && (
            <ul className="speakers-list">
              {discussion?.panelists?.map((panelist, index) => (
                <li key={`panelist-${index}`}>
                  <p>{panelist}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => handlePanelists(e, "remove", panelist)}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default PanelDiscussions;
