import moment from "moment";

export const shortenText = (str, maxLen) => {
  if (!str) return "";
  if (str.length <= maxLen) {
    return str;
  } else {
    return str.substring(0, maxLen) + "...";
  }
};

export const subMonths = (date, months) => {
  return new Date(date.setMonth(date.getMonth() - months));
};

export const addMonths = (date, months) => {
  return new Date(date.setMonth(date.getMonth() + months));
};

export const formatShortDate = (date, dateFormat = "DD.MM.YYYY") => {
  return moment(date).format(dateFormat);
};
export const convertTextToDate = (
  date,
  dateFormat = "DD.MM.YYYY",
  returnTodayOnInvalidDate = true
) => {
  let convertedDate;
  if (!!date) {
    convertedDate = moment(date, dateFormat).toDate();
  } else {
    convertedDate = null;
  }
  if (!!convertedDate) {
    return convertedDate;
  } else {
    if (returnTodayOnInvalidDate) {
      return new Date();
    }
    return null;
  }
};

export const getWeekNumbersInMonth = (year, month) => {
  const weeks = new Set(); // Using a Set to ensure unique week numbers
  const date = new Date(year, month, 1);

  // Loop through all days in the month
  while (date.getMonth() === month) {
    const weekNumber = getWeekNumber(date);
    weeks.add(weekNumber); // Add week number to the Set
    date.setDate(date.getDate() + 1); // Move to the next day
  }

  return Array.from(weeks); // Convert Set to Array and return it
};

export const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000; // 86400000 is the number of milliseconds in a day
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const removeUnderScore = (str) => {
  return (
    str.replace(/_/g, " ").charAt(0).toUpperCase() +
    str.replace(/_/g, " ").slice(1)
  );
};

export const getFirstAndLastWorkingDaysThisWeek = () => {
  let today = moment();
  let startOfWeek = moment().startOf("week").add(1, "days");
  let endOfWeek = moment().endOf("week").subtract(1, "days");

  let days = [];
  for (let i = 0; i < 5; i++) {
    days.push(
      moment()
        .startOf("week")
        .add(i + 1, "days")
    );
  }

  let workingWeekDays = days.map((day) => ({
    dayName: day.format("dd").charAt(0),
    dateDay: day.format("DD.MM.YY"),
  }));

  if (today.isoWeekday() === 6 || today.isoWeekday() === 7) {
    startOfWeek = moment().subtract(1, "week").startOf("week").add(1, "days");
    endOfWeek = moment().subtract(1, "week").endOf("week").subtract(1, "days");
  }

  let firstDay = today.isBefore(startOfWeek)
    ? null
    : startOfWeek.format("DD.MM.YYYY");

  let lastDay = today.isAfter(endOfWeek)
    ? today.format("DD.MM.YYYY")
    : endOfWeek.format("DD.MM.YYYY");

  return [firstDay, lastDay, workingWeekDays];
};

export const convertArrayToString = (collection) => {
  return JSON.stringify(collection);
};

export const convertDecimalToHours = (decimal) => {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return `${hours}h ${minutes}min`;
};
