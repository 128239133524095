import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { QuestionIcon, SandClockIcon } from "../../../icons";
import { UserIcon, Button, OutsideClick } from "../../ui";
import { shortenText } from "../../../utils/textFormat";
import { useSelector } from "react-redux";
import { fetchSinglePosition, resetCareerGrowth } from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useNavigate, useLocation } from "react-router-dom";

function MiniMatrixNode({ data, isConnectable }) {
  const navigate = useNavigate();
  const location = useLocation();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [showPopup, setShowPopup] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [doFetchSinglePosition] = useThunk(fetchSinglePosition);
  const [doResetCareerGrowth] = useThunk(resetCareerGrowth);

  const singleMatrixState = useSelector(
    (state) => state.matrices
  )?.singleMatrix;

  const singlePositionState = useSelector(
    (state) => state.positions
  )?.singlePosition;

  const currentUserState = useSelector((state) => state?.users)?.singleUser;

  const user =
    !!currentUserState && location.pathname.includes("personal-performance")
      ? currentUserState
      : localUserData;

  const isActivePosition = (blockId) => {
    if (!singleMatrixState?.active_position) {
      return false;
    }
    if (
      !!singleMatrixState?.active_position &&
      blockId === singleMatrixState?.active_position
    ) {
      return "active";
    }
    return "disabled";
  };

  const handleBlockClick = (position, event) => {
    event.stopPropagation();
    doFetchSinglePosition(position?.id);
    setSelectedPosition(position);
    setShowPopup(!showPopup);
  };

  const handleShowPosition = (id) => {
    doFetchSinglePosition(id);
    navigate("/your-career/position-details");
  };

  const handleResetCareerPath = () => {
    doResetCareerGrowth(user?.id);
  };

  return (
    <div className="text-updater-node">
      <Handle
        type="source"
        position={Position.Top}
        id="a"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
      <div className="custom-block mini-matrix">
        <div>
          {data?.position?.id === user?.position_id ? (
            <div className="block">
              <UserIcon
                userImage={user?.image}
                userName={user?.first_name}
                iconStyle={{
                  width: "30px",
                  height: "30px",
                  fontSize: "17px",
                }}
              />
              <div className="user-info">
                <p>
                  {shortenText(`${user?.first_name} ${user?.last_name}`, 8)}
                </p>
                <span>{shortenText(data?.position?.title, 8)}</span>
              </div>
              <div
                className={`popup ${
                  `${user?.first_name} ${user?.last_name}`.length > 8 ||
                  data?.position?.title.length > 8
                    ? "popup-show"
                    : ""
                }`}
              >
                <p>{`${user?.first_name} ${user?.last_name}`}</p>
                <span>{data?.position?.title}</span>
              </div>
            </div>
          ) : (
            <div
              style={{ display: "flex", gap: "5px" }}
              className={`block ${isActivePosition(data?.position?.id)} `}
              onClick={(event) => handleBlockClick(data?.position, event)}
            >
              {shortenText(data?.position?.title, 17)}

              <div>
                {isActivePosition(data?.position?.id) === "active" ? (
                  <SandClockIcon />
                ) : (
                  <QuestionIcon />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <OutsideClick
          style={{ position: "relative", zIndex: "3" }}
          onOutsideClick={() => setShowPopup(false)}
        >
          <div className="popup-wrapper">
            <h4>{data?.position?.title}</h4>
            <p>{singlePositionState?.description}</p>
            {isActivePosition(selectedPosition.id) !== "active" ? (
              <Button
                buttonStyles={{
                  padding: "10px",
                  marginTop: "20px",
                }}
                handleClick={() => handleShowPosition(selectedPosition.id)}
              >
                Explore and Start Career Growth
              </Button>
            ) : (
              <Button
                buttonStyles={{
                  padding: "10px",
                  marginTop: "20px",
                }}
                handleClick={handleResetCareerPath}
              >
                Reset Career Path
              </Button>
            )}
          </div>
        </OutsideClick>
      )}
    </div>
  );
}

export default MiniMatrixNode;
