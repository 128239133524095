import { useEffect, useState } from "react";
import { Button, TableList, TypeAhead, Status } from "../../ui";
import {
  PencilIcon,
  DeleteIcon,
  SearchIcon,
  SingleArrow,
} from "../../../icons";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormType } from "../../../store/form/formSlice";

import {
  fetchTemplates,
  deleteTemplate,
  fetchSingleTemplate,
  fetchUsersByManager,
  fetchUserReviewTemplates,
  fetchAssignedReviews,
} from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import {
  setActiveViewData,
  setSearchTerm,
} from "../../../store/pageViews/pageViewsSlice";
import { convertTextToDate } from "../../../utils/textFormat";
import { clearAssignedReviews } from "../../../store/performance/performanceSlice";

const DashboardView = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const columns = [
    { id: 1, label: "Employee info", slug: "info" },
    { id: 2, label: "Status", slug: "status" },
    { id: 3, label: "Expected date", slug: "date" },
    { id: 4, label: "", slug: "action" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [doFetchTemplates] = useThunk(fetchTemplates);
  const [doDeleteTemplate] = useThunk(deleteTemplate);
  const [doFetchSingleTemplate] = useThunk(fetchSingleTemplate);
  const [doFetchUsersByManager] = useThunk(fetchUsersByManager);
  const [doFetchUserReviewTemplates] = useThunk(fetchUserReviewTemplates);
  const [doFetchUpcomingReviews] = useThunk(fetchAssignedReviews);

  const allTemplatesState = useSelector(
    (state) => state.performance
  )?.templates;
  const managedUsersState = useSelector((state) => state?.users)?.data;
  const userReviewTemplatesState = useSelector(
    (state) => state?.users
  )?.userReviewTemplate;
  const searchTerm = useSelector((state) => state?.pageViews)?.searchTerm;
  const upcomingReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.assigned;

  const [notScheduledRows, setNotScheduledRows] = useState([]);
  const [overdueRows, setOverdueRows] = useState([]);
  const [upcomingRows, setUpcomingRows] = useState([]);
  const [searchUserOptions, setSearchUserOptions] = useState(
    managedUsersState?.length > 0 ? managedUsersState : []
  );

  const handleSearch = (searchTerm) => {
    dispatch(setSearchTerm(searchTerm));
  };
  const handleShowForm = () => {
    navigate("/personal-performance/form");
  };

  const handleEdit = (templateId) => {
    doFetchSingleTemplate(templateId);
    dispatch(setFormType("edit"));
    navigate("/personal-performance/form");
  };

  const handleDelete = (templateId) => {
    doDeleteTemplate(templateId);
  };

  const handleUserSelect = (user) => {
    navigate("/personal-performance", {
      state: { userId: user?.id, expandedViews: [1] },
    });
  };

  const handleNotScheduledRowClick = (row) => {
    const user = Array.isArray(managedUsersState)
      ? managedUsersState?.find(
          (user) =>
            user?.id ===
            userReviewTemplatesState?.find(
              (template) => template?.id === row?.id
            )?.user_id
        )
      : null;
    navigate("/personal-performance/form", {
      state: {
        user,
        data: userReviewTemplatesState?.find(
          (template) => template?.id === row?.id
        ),
      },
    });
  };

  const handleOverdueRowClick = (row) => {
    dispatch(
      setActiveViewData({
        userId: row?.userId,
        expandedViews: [1],
        expandedAssignedReviewId: row?.id,
      })
    );
    navigate("/personal-performance", {
      state: {
        userId: row?.userId,
        expandedViews: [1],
        expandedAssignedReviewId: row?.id,
      },
    });
  };

  const handleUpcomingRowClick = (row) => {
    dispatch(
      setActiveViewData({
        userId: row?.userId,
        expandedViews: [1],
        expandedAssignedReviewId: row?.id,
      })
    );
    navigate("/personal-performance", {
      state: {
        userId: row?.userId,
        expandedViews: [1],
        expandedAssignedReviewId: row?.id,
      },
    });
  };

  useEffect(() => {
    doFetchTemplates();
    doFetchUsersByManager(localUserData?.id);

    return () => {
      dispatch(clearAssignedReviews());
    };
  }, []);

  useEffect(() => {
    if (managedUsersState?.length > 0) {
      doFetchUserReviewTemplates(managedUsersState?.map((user) => user?.id));
      doFetchUpcomingReviews({
        managedUsersIds: managedUsersState?.map((user) => user?.id),
      });
    }
  }, [managedUsersState]);

  useEffect(() => {
    if (managedUsersState?.length > 0 && searchUserOptions?.length === 0) {
      setSearchUserOptions(managedUsersState);
    }
  }, [searchUserOptions, managedUsersState]);

  useEffect(() => {
    const rows = userReviewTemplatesState?.map((template) => ({
      id: template?.id,
      info: (
        <div>
          <strong>{template?.name}</strong>
          <p>{template?.title}</p>
        </div>
      ),
      status: (
        <div className="dashboard-status-wrapper">
          <Status status={{ label: "Not scheduled", color: "red" }} />
        </div>
      ),
      date: template?.date,
      action: <SingleArrow />,
    }));
    setNotScheduledRows(rows);
  }, [userReviewTemplatesState]);

  useEffect(() => {
    if (upcomingReviewsState?.length > 0) {
      const rows = upcomingReviewsState?.map((review) => ({
        id: review?.id,
        userId: review?.user?.id,
        info: (
          <div>
            <strong>{`${review?.user?.first_name} ${review?.user?.last_name}`}</strong>
            <p>{review?.title}</p>
          </div>
        ),
        status: (
          <div className="dashboard-status-wrapper">
            {convertTextToDate(review?.schedule_date) < new Date() ? (
              <Status status={{ label: "Overdue", color: "red" }} />
            ) : (
              <Status status={{ label: "Upcoming", color: "green" }} />
            )}
          </div>
        ),
        date: review?.schedule_date,
        action: <SingleArrow />,
      }));
      setUpcomingRows(
        rows
          ?.filter((row) => convertTextToDate(row?.date) >= new Date())
          .sort(
            (a, b) => convertTextToDate(a?.date) - convertTextToDate(b?.date)
          )
      );
      setOverdueRows(
        rows
          ?.filter((row) => convertTextToDate(row?.date) < new Date())
          .sort(
            (a, b) => convertTextToDate(a?.date) - convertTextToDate(b?.date)
          )
      );
    }
  }, [upcomingReviewsState]);

  useEffect(() => {
    if (searchTerm?.length > 0 && Array.isArray(searchUserOptions)) {
      setSearchUserOptions(
        searchUserOptions?.filter((user) =>
          `${user?.first_name} ${user?.last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setSearchUserOptions(managedUsersState);
    }
  }, [searchTerm]);

  return (
    <div className="dashboard-flex-item w-60">
      <div className="dashboard-content-head dashboard-block">
        <p>Search users</p>
        <div className="dashboard-search-wrapper">
          <TypeAhead
            placeholder="Search user"
            icon={<SearchIcon />}
            options={searchUserOptions || []}
            slug={(option) => `${option?.first_name} ${option?.last_name}`}
            handleSearch={handleSearch}
            handleSelect={handleUserSelect}
          />
        </div>
      </div>
      <div className="dashboard-block">
        <TableList
          title="Not scheduled"
          rows={notScheduledRows}
          columns={columns}
          listKey="not-scheduled"
          onClick={handleNotScheduledRowClick}
          noDataString="No upcoming reviews"
        />
      </div>
      <div className="dashboard-block">
        <TableList
          title="Overdue"
          rows={overdueRows}
          columns={columns}
          listKey="overdue"
          onClick={handleOverdueRowClick}
          noDataString="No upcoming reviews"
        />
      </div>
      <div className="dashboard-block">
        <TableList
          title="Upcoming reviews"
          rows={upcomingRows}
          columns={columns}
          listKey="upcoming"
          noDataString="No upcoming reviews"
          onClick={handleUpcomingRowClick}
        />
      </div>
      <div className="dashboard-block">
        <div className="dashboard-templates">
          <div className="dashboard-templates-header">
            <h3>Create new performance or choose a Template</h3>
            <Button handleClick={handleShowForm}>New Performance</Button>
          </div>
          <div className="dashboard-templates-content">
            <h3>Templates</h3>
            <div className="dashboard-templates-wrapper">
              {!allTemplatesState?.length ? (
                <p>No Templates</p>
              ) : (
                allTemplatesState?.map((template) => (
                  <div
                    className="dashboard-template"
                    key={`template-${template?.id}`}
                  >
                    <div className="dashboard-template-content">
                      <p>
                        <strong>{template?.title}</strong>
                      </p>
                      <p>{template?.created_at}</p>
                      <div className="dashboard-template-actions">
                        <div
                          className="template-action"
                          onClick={() => handleEdit(template?.id)}
                        >
                          <PencilIcon />
                        </div>
                        <div
                          className="template-action"
                          onClick={() => handleDelete(template?.id)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
