import React from "react";

const ActivityData = ({ className, activity, activityItem }) => {
  const renderData = () => {
    switch (activity) {
      case "conferenceAttendances":
        return (
          <>
            <div className="column">
              <p className="column-title">Speaker / Presenter</p>
              {activityItem?.speakers?.map((speaker, index) => (
                <p key={`speaker-${index}`}>{speaker.name}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Topics</p>
              {activityItem?.topics?.map((topic, index) => (
                <p key={`topic-${index}`}>{topic.title}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Location</p>
              <p>{activityItem?.location}</p>
            </div>
          </>
        );
      case "workshopSeminars":
        return (
          <>
            <div className="column">
              <p className="column-title">Speakers / Instructors</p>
              {activityItem?.speakers?.map((speaker, index) => (
                <p key={`speaker-${index}`}>{speaker.name}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Topics</p>
              {activityItem?.topics?.map((topic, index) => (
                <p key={`topic-${index}`}>{topic.title}</p>
              ))}
            </div>
            <div className="column flex flex-col gap-16">
              <div>
                <p className="column-title">Location</p>
                <p>{activityItem?.location}</p>
              </div>
              <div>
                <p className="column-title">Host organization</p>
                <p>{activityItem?.host}</p>
              </div>
            </div>
          </>
        );
      case "publicSpeakings":
        return (
          <>
            <div className="column flex flex-col">
              <p className="column-title">Notes</p>
              {!!activityItem?.notes && (
                <div className="text-data">{activityItem?.notes}</div>
              )}
            </div>
            <div className="column">
              <p className="column-title">Topics</p>
              {activityItem?.topics?.map((topic, index) => (
                <p key={`topic-${index}`}>{topic.title}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Location</p>
              <p>{activityItem?.location}</p>
            </div>
          </>
        );
      case "panelDiscussions":
        return (
          <>
            <div className="column">
              <p className="column-title">Panelist</p>
              {activityItem?.panelists?.map((panelist, index) => (
                <p key={`panelist-${index}`}>{panelist.name}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Topics</p>
              {activityItem?.topics?.map((topic, index) => (
                <p key={`topic-${index}`}>{topic.title}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Location</p>
              <p>{activityItem?.location}</p>
            </div>
          </>
        );
      case "professionalOrganizations":
        return (
          <>
            <div className="column">
              <p className="column-title">Activities</p>
              {!!activityItem?.activities && (
                <div className="text-data">{activityItem?.activities}</div>
              )}
            </div>
            <div className="column">
              <p className="column-title">Benefits</p>
              {activityItem?.benefits?.map((benefit, index) => (
                <p>{benefit.title}</p>
              ))}
            </div>
            <div className="column">
              <p className="column-title">Ongoing</p>
              <p>{activityItem?.ongoing ? "Yes" : "No"}</p>
            </div>
          </>
        );
      case "committeeInvolvements":
        return (
          <>
            <div className="column column-spread-2">
              <p className="column-title">Responsibilities</p>
              {!!activityItem?.responsibilities && (
                <div className="text-data">
                  {activityItem?.responsibilities}
                </div>
              )}
            </div>
            <div className="column flex flex-col items-center">
              <p className="column-title">Achievements</p>
              {!!activityItem?.achievements && (
                <div className="text-data">{activityItem?.achievements}</div>
              )}
            </div>
          </>
        );
      case "advancedDegrees":
        return (
          <>
            <div className="column column-spread-2">
              <p className="column-title">Courses</p>
              {!!activityItem?.courses && (
                <div className="text-data">{activityItem?.courses}</div>
              )}
            </div>
            <div className="column">
              <p className="column-title">Field</p>
              <p>{activityItem?.field}</p>
            </div>
          </>
        );
      case "certificationPrograms":
        return (
          <>
            <div className="column">
              <p className="column-title">Requirements</p>
              {!!activityItem?.requirements && (
                <div className="text-data">{activityItem?.requirements}</div>
              )}
            </div>
            <div className="column flex flex-col items-center">
              <p className="column-title">Benefits</p>
              {!!activityItem?.benefits && (
                <div className="text-data">{activityItem?.benefits}</div>
              )}
            </div>
            <div className="column">
              <p className="column-title">Field</p>
              <p>{activityItem?.field}</p>
            </div>
          </>
        );
      default:
        break;
    }
  };
  return (
    <div className={`activity-data ${className}`}>
      {renderData()}
      <div className="column actions" />
    </div>
  );
};

export default ActivityData;
