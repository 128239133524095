import { useEffect, useState, useRef } from "react";
import PerformanceReviewTimeline from "./PerformanceReviewTimeline";
import ReviewLists from "./ReviewLists";
import { useSelector } from "react-redux";
import { useThunk } from "../../../../hooks/useThunk";
import {
  fetchAssignedReviews,
  fetchCompletedReviews,
  fetchUserReviewTemplates,
} from "../../../../store";

const PerformanceReview = ({ isExpanded, selectedUser }) => {
  const contentRef = useRef(null);

  const [doFetchAssignedReviews, assignedLoading] =
    useThunk(fetchAssignedReviews);
  const [doFetchCompletedReviews, completedLoading] = useThunk(
    fetchCompletedReviews
  );
  const [doFetchUserReviewTemplates, templatesLoading] = useThunk(
    fetchUserReviewTemplates
  );

  const assignedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.assigned;
  const completedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.completed;
  const userReviewTemplatesState = useSelector(
    (state) => state?.users
  )?.usersReviewTemplate;

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
      doFetchAssignedReviews({
        user_id: selectedUser?.id,
      });
      doFetchCompletedReviews({ user_id: selectedUser?.id });
      doFetchUserReviewTemplates([selectedUser?.id]);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (
      isExpanded &&
      contentRef.current &&
      !assignedLoading &&
      !completedLoading &&
      !templatesLoading
    ) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [
    assignedReviewsState,
    completedReviewsState,
    userReviewTemplatesState,
    assignedLoading,
    completedLoading,
    templatesLoading,
  ]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view performance-review">
        <PerformanceReviewTimeline selectedUser={selectedUser} />
        <hr />
        <ReviewLists selectedUser={selectedUser} />
      </div>
    </div>
  );
};

export default PerformanceReview;
