import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchUsers = createAsyncThunk(
  "users/fetch",
  async ({ page = 1, searchTerm = "" }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`, {
        params: {
          page,
          searchTerm,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleUser = createAsyncThunk(
  "users/singleUser",
  async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUsersByManager = createAsyncThunk(
  "users/manager/fetch",
  async (managerId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`);
      return response?.data?.optionData?.filter(
        (user) => user?.manager?.id === managerId
      );
    } catch (error) {
      throw error;
    }
  }
);

export const createUser = createAsyncThunk("users/create", async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateUser = createAsyncThunk("users/update", async (payload) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/users/${payload.id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateUserAvailability = createAsyncThunk(
  "/user/availability",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/availability`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk("users/delete", async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/users/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchUserReviewTemplates = createAsyncThunk(
  "users/review-templates/fetch",
  async (usersIds = []) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/users/reviews`, {
        users: usersIds,
      });
      return Object.values(response.data)
        .flat()
        .sort((a, b) => a.date - b.date);
    } catch (error) {
      throw error;
    }
  }
);

export const deleteUserReviewTemplate = createAsyncThunk(
  "users/review-templates/delete",
  async (reviewId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/review/${reviewId}/delete`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
