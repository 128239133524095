import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMatrices,
  fetchMatrixByPosition,
  createMatrix,
  deleteMatrix,
  updateMatrix,
  startCareerGrowth,
  resetCareerGrowth,
  fetchUserCareerPath,
  fetchCareerPathHistory,
} from "./matricesThunks";

const matricesSlice = createSlice({
  name: "matrices",
  initialState: {
    data: [],
    singleMatrix: null,
    userCareerPath: null,
    careerPathHistory: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchMatrices.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchMatrixByPosition.fulfilled, (state, action) => {
      state.singleMatrix = action.payload;
    });
    builder.addCase(createMatrix.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(updateMatrix.fulfilled, (state, action) => {
      const updatedState = state.data.map((matrix) => {
        return matrix.id === action.payload.id ? action.payload : matrix;
      });
      state.data = updatedState;
    });
    builder.addCase(deleteMatrix.fulfilled, (state, action) => {
      const filteredMatrices = state.data.filter(
        (matrix) => matrix.id !== action.meta.arg
      );
      state.data = filteredMatrices;
    });
    builder.addCase(startCareerGrowth.fulfilled, (state, action) => {
      state.singleMatrix = {
        ...state.singleMatrix,
        active_position: action.meta.arg.positionId,
      };
    });
    builder.addCase(resetCareerGrowth.fulfilled, (state, action) => {
      state.singleMatrix = {
        ...state.singleMatrix,
        active_position: null,
      };
    });
    builder.addCase(fetchUserCareerPath.fulfilled, (state, action) => {
      state.userCareerPath = action.payload;
    });
    builder.addCase(fetchCareerPathHistory.fulfilled, (state, action) => {
      state.careerPathHistory = action.payload;
    });
  },
});

export const matricesReducer = matricesSlice.reducer;
