import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CardFilter } from "../../../ui";
import { CircleCheckIcon, ClockIcon, TIcon } from "../../../../icons";
import "./styles.scss";
import { dummuMeetings } from "./dummyData";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const MeetingsReview = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const meetingsState = useSelector((state) => state.meetings)?.allMeetings;

  const meetings = dummuMeetings;
  const doughnutData = {
    labels: [],
    datasets: [
      {
        label: "Number of meetings",
        data: [
          meetingsState?.length,
          8,
          8,
          meetingsState?.filter((meet) => meet?.type === 1)?.length,
        ],
        backgroundColor: ["#14B4BA", "#A9CE5B", "#F07171", "#ebbe4d"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
      },
    },
  };

  return (
    <div className="meetings-review">
      <div className="meetings">
        <div className="one-on-one">
          <div className="title-line">
            <h2 className="title">1-1 Meetings</h2>
          </div>
          <ul className="one-on-one-list">
            {meetingsState
              .filter((meeting) => meeting?.type === 1)
              .map((meeting, index) => (
                <li key={`meet-${index}`}>
                  <div className="column">
                    <p className="name">{meeting?.others[0]?.name}</p>
                  </div>
                  <p className="column">{meeting?.others[0]?.position}</p>
                  <div className="column">
                    {!!meeting.meeting_date ? (
                      moment(
                        meeting.meeting_date,
                        "DD.MM.YYYY HH:mm:ss"
                      ).format("DD.MM.yyy")
                    ) : (
                      <div className="not-scheduled">Not scheduled</div>
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="meetings-attendance">
          <div className="title-line">
            <h2 className="title">Meeting attendance</h2>
          </div>
          <div className="cards">
            <CardFilter
              borderColor="#109095"
              backgroundColor="#14B4BA"
              icon={<TIcon />}
              count={meetingsState?.length}
              title="Total Meetings"
            />
            <CardFilter
              borderColor="#86A347"
              backgroundColor="#A9CE5B"
              icon={<CircleCheckIcon />}
              count={meetings?.length}
              title="Attended Meetings"
            />
            <CardFilter
              borderColor="#B35656"
              backgroundColor="#F07171"
              icon={<ClockIcon />}
              count={meetings?.length}
              title="Unattended Meetings"
            />
            <CardFilter
              borderColor="#ba8e23"
              backgroundColor="#ebbe4d"
              icon={<CircleCheckIcon />}
              count={meetingsState?.filter((meet) => meet?.type === 1)?.length}
              title="1-1 Meetings"
            />
          </div>
        </div>
      </div>
      <div className="chart">
        <div className="doughnut-wrapper">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>
      </div>
    </div>
  );
};

export default MeetingsReview;
