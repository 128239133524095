import { Routes, Route } from "react-router-dom";

import PersonalPerformance from "../components/PersonalPerformance";
import Dashboard from "../components/PersonalPerformance/dashboard";
import AdminProtectedRoute from "./AdminProtectedRoute";
import { useSelector } from "react-redux";

const PersonalPerformanceRoutes = () => {
  const loggedUserState = useSelector((state) => state?.users)?.loggedInUser;
  return (
    <Routes>
      <Route path="/" element={<PersonalPerformance />} />

      <Route
        element={
          <AdminProtectedRoute
            isAllowed={!!loggedUserState?.permissions?.length}
          />
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/form" element={<Dashboard />} />
        <Route path="/drafts" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default PersonalPerformanceRoutes;
