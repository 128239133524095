import React, { useEffect, useState, useCallback } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
} from "reactflow";
import "reactflow/dist/style.css";

import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import "./organisation.scss";
import "./customNode.scss";
import { Input, Modal } from "../../../ui";

import {
  fetchPositions,
  fetchMatrices,
  createMatrix,
  deleteMatrix,
  updateMatrix,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";

import BlockSelect from "./BlockSelect";
import CustomNode from "./CustomNode";

const nodeTypes = { customNode: CustomNode };

const nodeOrigin = [0.5, 0.5];

const edgeOptions = {
  type: "smoothstep",
  style: {
    stroke: "#34C1DF",
  },
};

const Organisation = () => {
  const [allPositions, setAllPositions] = useState([]);
  const [positionsCollection, setPositionsCollection] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [matrixName, setMatrixName] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentMatrix, setCurrentMatrix] = useState(null);
  const [resetMatrixKey, setResetMatrixKey] = useState(0);
  const [resetActionsKey, setResetActionsKey] = useState(100);

  const [doFetchPositions] = useThunk(fetchPositions);
  const [doFetchMatrices] = useThunk(fetchMatrices);
  const [doCreateMatrix] = useThunk(createMatrix);
  const [doDeleteMatrix] = useThunk(deleteMatrix);
  const [doUpdateMatrix] = useThunk(updateMatrix);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const positionsState = useSelector((state) => state.positions)?.data;
  const matricesState = useSelector((state) => state.matrices)?.data;

  useEffect(() => {
    doFetchPositions();
    doFetchMatrices();
  }, []);

  useEffect(() => {
    setNodes(positionsCollection?.nodes || []);
    setEdges(positionsCollection?.edges || []);
  }, [positionsCollection]);

  useEffect(() => {
    const formatedPositions = positionsState.map((position) => {
      return {
        id: position.id,
        title: position.title,
      };
    });
    setAllPositions(formatedPositions);
  }, [positionsState]);

  const handleResetMatrix = () => {
    setResetMatrixKey((prevKey) => prevKey + 1);
  };
  const handleResetActions = () => {
    setResetActionsKey((prevKey) => prevKey + 1);
  };

  const saveMatrix = () => {
    let payload = {
      name: matrixName,
      matrix: {
        nodes,
        edges,
      },
    };
    if (!!matrixName && !currentMatrix) {
      doCreateMatrix(payload);
    }
    if (!!currentMatrix) {
      payload = {
        ...payload,
        id: currentMatrix,
        name: matricesState.find((matrix) => matrix.id === currentMatrix)?.name,
      };
      doUpdateMatrix(payload);
    }
    setPositionsCollection([]);
    setCurrentMatrix(null);
    handleModalClose();
  };

  const handleSelectMatrix = (id) => {
    const selectedMatrix = matricesState.find((matrix) => matrix.id === id);
    setCurrentMatrix(selectedMatrix.id);
    setPositionsCollection(selectedMatrix.matrix);
  };

  useEffect(() => {
    const preselectedMatrix = matricesState[0]?.id
      ? matricesState[0]?.id
      : null;
    if (!!matricesState?.length) {
      handleSelectMatrix(preselectedMatrix);
    }
  }, [matricesState]);

  const handleModalClose = () => {
    setShowModal(false);
    setShowConfirmModal(false);
  };

  const handleChangeName = (event) => {
    setMatrixName(event.target.value);
  };

  const matrixActions = [
    {
      value: 1,
      label: "Save",
    },
    {
      value: 2,
      label: "New",
    },
    {
      value: 3,
      label: "Delete",
    },
  ];

  const filteredMatrixActions = () => {
    if (!nodes?.length) {
      return [matrixActions.find((matrix) => matrix.value === 2)];
    }
    if (!!nodes?.length && !currentMatrix) {
      return matrixActions.filter((matrix) => matrix.value !== 3);
    }
    return matrixActions;
  };

  const reducedMatrixActions = filteredMatrixActions();

  const handleSelectAction = (event) => {
    if (event === 1) {
      setShowModal(true);
      handleResetActions();
      return;
    }
    if (event === 2) {
      setCurrentMatrix(null);
      setPositionsCollection([]);
      setNodes([]);
      setEdges([]);
      handleResetMatrix();
      handleResetActions();
      return;
    }
    missingConnection();
    setShowConfirmModal(true);
    handleResetActions();
  };

  const handleDeleteMatrix = () => {
    doDeleteMatrix(currentMatrix);
    setPositionsCollection([]);
    setCurrentMatrix(null);
    handleModalClose();
  };

  const missingConnection = () => {
    const edgeIds = edges?.flatMap(({ source, target }) => [source, target]);
    const nodeIds = nodes?.map(({ id }) => id);

    return nodeIds?.every((id) => edgeIds?.includes(id));
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => {
      setEdges((eds) => addEdge(connection, eds));
    },
    [setEdges]
  );
  const handleAddPosition = (position) => {
    const newNode = {
      id: `${position?.id}`,
      type: "customNode",
      position: { x: 150, y: 150 },
      data: { position },
    };
    setNodes([...nodes, newNode]);
  };

  return (
    <div className="career-content">
      <div className="career-header">
        <h2>Organisation Hierarchy</h2>
        <div className="header-actions matrix-actions">
          <DropdownSelect
            key={resetMatrixKey}
            options={matricesState}
            handleSelect={handleSelectMatrix}
            type="primary"
            placeholder="Choose career path"
            idKey="id"
            labelKey="name"
            preselected={matricesState.find(
              (matrix) => matrix?.id === currentMatrix
            )}
          />
          <DropdownSelect
            key={resetActionsKey}
            options={reducedMatrixActions}
            handleSelect={handleSelectAction}
            type="primary"
            background="dark"
            placeholder="Actions"
          />
        </div>
      </div>
      <div className="matrix-container">
        <div
          style={{
            width: "calc(100vw - 350px)",
            height: "calc(100vh - 200px)",
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            defaultEdgeOptions={edgeOptions}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            snapToGrid={true}
            nodeOrigin={nodeOrigin}
          >
            <Background variant="dots" gap={12} size={1} />
            <div className="updatenode__controls">
              <BlockSelect
                positionsState={positionsState}
                selectPosition={handleAddPosition}
              />
            </div>
          </ReactFlow>
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={handleModalClose}
        title={!currentMatrix ? "Add career path name" : "Save career path"}
        disabledConfirm={!missingConnection()}
        handleConfirm={saveMatrix}
      >
        {!missingConnection() ? (
          <label>All blocks must be connected.</label>
        ) : (
          <>
            <label>
              {!currentMatrix ? "Career path name" : "Are you sure?"}
            </label>
            {!currentMatrix && (
              <Input
                type="input"
                placeholder="Enter matrix name"
                name="name"
                value={matrixName}
                handleChange={handleChangeName}
              />
            )}
          </>
        )}
      </Modal>
      <Modal
        show={showConfirmModal}
        handleClose={handleModalClose}
        title="Confirm delete"
        handleConfirm={handleDeleteMatrix}
      >
        <label>Are you sure?</label>
      </Modal>
    </div>
  );
};

export default Organisation;
