import { useEffect, useState } from "react";

import {
  fetchTeams,
  deleteTeam,
  createTeam,
  updateTeam,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Pagination, Table } from "../../../ui";
import TeamsModal from "./TeamsModal";
import { resetTeamsMessage } from "../../../../store/teams/teamsSlice";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Teams = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);

  const [doFetchTeams] = useThunk(fetchTeams);
  const [doDeleteTeam] = useThunk(deleteTeam);
  const [doCreateTeam, isLoading] = useThunk(createTeam);
  const [doUpdateTeam] = useThunk(updateTeam);
  const [doShowToastMessage] = useToastMessage(resetTeamsMessage);

  const teamsState = useSelector((state) => state.teams)?.teams;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const formType = useSelector((state) => state.form)?.type;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.teams)?.message;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      doFetchTeams({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchTeams]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: teamsState?.teamData?.map((team) => ({
      ...team,
      leadName: `${team?.lead?.first_name || ""} ${
        team?.lead?.last_name || ""
      }`,
    })),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
      {
        id: 3,
        label: "Team lead",
        slug: "leadName",
      },
    ],
  };

  const handleEdit = (team) => {
    delete team.leadName;
    dispatch(setFormType("edit"));
    dispatch(setFormData(team));
    dispatch(setActiveModal("Teams-modal"));
  };
  const handleDelete = () => {
    doDeleteTeam(selectedTeam?.id);
    setSelectedTeam(null);
    setShowConfirmationModal(false);
  };
  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (team) => {
    if (formType === "edit") {
      doUpdateTeam(team);
      handleModalClose();
      return;
    }
    doCreateTeam(team);
    handleModalClose();
  };

  const handleShowConfirmModal = (team) => {
    setSelectedTeam(team);
    setShowConfirmationModal(true);
  };
  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleShowConfirmModal}
        hasActions={true}
      />

      <Pagination
        currentPage={page}
        total={teamsState?.pageData?.total}
        onPageChange={setPage}
        limit={teamsState?.pageData?.perPage}
        position="center"
      />
      {activeModalState === "Teams-modal" ? (
        <TeamsModal
          showModal={activeModalState === "Teams-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        ""
      )}
      <Modal
        show={showConfirmationModal}
        handleModalClose={() => setShowConfirmationModal(false)}
        handleConfirm={handleDelete}
        handleClose={() => {
          setSelectedTeam(null);
          setShowConfirmationModal(false);
        }}
      >
        <p>Are you sure you want to delete this team?</p>
      </Modal>
    </div>
  );
};

export default Teams;
