import React, { useState, useEffect } from "react";
import Question from "./Question";
import { AddIcon } from "../../../icons";
import { createSurvey, updateSurvey, deleteSurvey } from "../../../store";
import { useSelector } from "react-redux";
import { useThunk } from "../../../hooks/useThunk";
import { Button } from "../../ui";
import { useNavigate } from "react-router-dom";

const SurveyCreator = () => {
  const [questions, setQuestions] = useState([
    {
      id: 1,
      content: "",
      type: 1,
      choices: [],
      isRequired: false,
    },
  ]);

  const navigate = useNavigate();

  const surveyData = useSelector((state) => state.pageViews)?.data;
  const surveyCreatorState = useSelector(
    (state) => state.surveys
  )?.surveyCreator;
  const pageDataState = useSelector((state) => state.pageViews)?.data;

  const [doCreateSurvey] = useThunk(createSurvey);
  const [doUpdateSurvey] = useThunk(updateSurvey);
  const [doDeleteSurvey] = useThunk(deleteSurvey);

  useEffect(() => {
    if (!!surveyData?.row?.questions?.length) {
      setQuestions(surveyData?.row?.questions);
    }
  }, [surveyData]);

  const handleAddQuestion = () => {
    const newId = questions[questions.length - 1].id + 1;
    setQuestions([
      ...questions,
      {
        id: newId,
        content: "",
        type: 1,
        choices: [],
        isRequired: false,
      },
    ]);
  };

  const handleRemoveQuestion = (questionId) => {
    const filteredQuestions = questions.filter(
      (question) => question.id !== questionId
    );
    setQuestions(filteredQuestions);
  };

  const handleUpdateQuestion = (questionId, question) => {
    const updatedQuestions = questions.map((item) => {
      if (item.id === questionId) {
        return question;
      }
      return item;
    });
    setQuestions(updatedQuestions);
  };

  const handleSaveDraft = () => {
    const payload = { ...surveyCreatorState, questions: questions, status: 3 };
    if (!pageDataState.isEdit) {
      doCreateSurvey(payload);
    } else {
      doUpdateSurvey(payload);
    }
    navigate("/survey/ongoing");
  };

  const handleCreateSurvey = () => {
    const payload = { ...surveyCreatorState, questions: questions, status: 1 };
    doCreateSurvey(payload)
      .then(() => {
        navigate("/survey/ongoing");
      })
      .catch((err) => {
        console.log(err);
      });
    // doDeleteSurvey(surveyData?.row?.id);
  };

  return (
    <>
      <div className="question-creator-header">
        <h2>{surveyCreatorState?.title}</h2>
        <div className="question-creator-actions">
          <Button variant="secondary" handleClick={handleSaveDraft}>
            Save draft
          </Button>
          <Button handleClick={handleCreateSurvey}>Send</Button>
        </div>
      </div>
      <div className="question-creator">
        {questions?.map((question) => (
          <Question
            key={`question-${question?.id}`}
            question={question}
            handleRemoveQuestion={handleRemoveQuestion}
            handleUpdateQuestion={handleUpdateQuestion}
          />
        ))}
        <div className="add-question">
          <div className="add-icon" onClick={handleAddQuestion}>
            <AddIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyCreator;
