import { useState, useEffect } from "react";

import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import { Button, OutsideClick } from "../../../ui";
import "./organisation.scss";

const BlockSelect = ({
  positionsState,
  selectPosition,
  selectedBlock,
  selectConnection,
  handleRemoveBlock,
  positionY,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleSelectPosition = (positionId) => {
    const currentPosition = positionsState?.find(
      (position) => position.id === positionId
    );
    selectPosition(currentPosition);
  };

  const handleSelectConnection = (positionsId) => {
    selectConnection(positionsId);
  };

  const availablePositions = () => {
    return positionsState;
  };

  const handleRemove = (selectedBlock) => {
    handleRemoveBlock(selectedBlock.id);
  };

  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <OutsideClick onOutsideClick={handleClosePopup}>
      <Button handleClick={handleTogglePopup}>Add Position</Button>
      {showPopup && (
        <div className="career-form-wrapper">
          <form>
            <div className="career-form-group">
              <label>Position</label>
              <DropdownSelect
                options={availablePositions()}
                handleSelect={handleSelectPosition}
                type="primary"
                placeholder="Choose position"
                idKey="id"
                labelKey="title"
                preselected={!!selectedBlock ? selectedBlock : []}
                selectListPosition={positionY > 500 ? "top" : "bottom"}
              />
            </div>
          </form>
        </div>
      )}
    </OutsideClick>
  );
};

export default BlockSelect;
