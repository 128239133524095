import { useEffect, useState } from "react";

import {
  fetchResponsibilities,
  createResponsibility,
  updateResponsibility,
  deleteResponsibility,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Pagination, Table } from "../../../ui";
import ResponsibilitiesModal from "./ResponsibilitiesModal";
import { resetResponsibilityMessage } from "../../../../store/responsibilities/responsibilitiesSlice";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Responsibilities = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);

  const [doFetchResponsibilities] = useThunk(fetchResponsibilities);
  const [doDeleteResponsibility] = useThunk(deleteResponsibility);
  const [doCreateResponsibility, isLoading] = useThunk(createResponsibility);
  const [doUpdateResponsibility] = useThunk(updateResponsibility);
  const [doShowToastMessage] = useToastMessage(resetResponsibilityMessage);

  const responsibilitiesState = useSelector(
    (state) => state.responsibilities
  )?.responsibilities;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const formType = useSelector((state) => state.form)?.type;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.responsibilities)?.message;

  const [selectedResponsibility, setSelectedResponsibility] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      doFetchResponsibilities({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchResponsibilities]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: responsibilitiesState?.map((responsibility) => ({
      ...responsibility,
    })),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
    ],
  };

  const handleEdit = (responsibility) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(responsibility));
    dispatch(setActiveModal("Responsibilities-modal"));
  };
  const handleDelete = () => {
    doDeleteResponsibility(selectedResponsibility?.id);
    handleCloseConfirmationModal();
  };
  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (responsibility) => {
    if (formType === "edit") {
      doUpdateResponsibility(responsibility);
      handleModalClose();
      return;
    }
    doCreateResponsibility(responsibility);
    handleModalClose();
  };

  const handleShowConfirmationModal = (responsibility) => {
    setSelectedResponsibility(responsibility);
    dispatch(setActiveModal("confirmation-modal"));
  };

  const handleCloseConfirmationModal = () => {
    setSelectedResponsibility(null);
    dispatch(setActiveModal(""));
  };

  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleShowConfirmationModal}
        hasActions={true}
      />

      {activeModalState === "Responsibilities-modal" ? (
        <ResponsibilitiesModal
          showModal={activeModalState === "Responsibilities-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        ""
      )}
      <Modal
        title="Delete Responsibility"
        show={activeModalState === "confirmation-modal"}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={handleDelete}
      >
        <p>Are you sure you want to delete this responsibility?</p>
      </Modal>
    </div>
  );
};

export default Responsibilities;
