export const dummyCompensation = {
  review: {
    sender: "Bojan Sekulic",
    description: "Review info",
    dueDate: "27.03.2023",
    requiredScore: 4,
    requirements: [
      {
        id: 1,
        title: "Requirement 1",
        description: "Lorem ipsum dolor sit amet.",
        completed: true,
      },
      {
        id: 2,
        title: "Requirement 2",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, ipsam!",
        completed: true,
      },
      {
        id: 3,
        title: "Requirement 3",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae dignissimos consectetur at voluptatibus! Animi mollitia impedit deleniti, aliquam ipsam eius!",
        completed: true,
      },
      {
        id: 4,
        title: "Requirement 4",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        completed: false,
      },
    ],
  },
  raiseDates: [
    { event: "Performance bonus", date: "15.03.2024" },
    { event: "Yearly salary raise", date: "01.12.2023" },
    { event: "Another example", date: "05.01.2024" },
    { event: "Another example", date: "10.01.2024" },
    { event: "Performance bonus", date: "15.03.2024" },
    { event: "Yearly salary raise", date: "01.12.2023" },
    { event: "Another example", date: "05.01.2024" },
    { event: "Another example", date: "10.01.2024" },
    { event: "Performance bonus", date: "15.03.2024" },
    { event: "Yearly salary raise", date: "01.12.2023" },
    { event: "Another example", date: "05.01.2024" },
    { event: "Another example", date: "10.01.2024" },
    { event: "Performance bonus", date: "15.03.2024" },
    { event: "Yearly salary raise", date: "01.12.2023" },
    { event: "Another example", date: "05.01.2024" },
    { event: "Another example", date: "10.01.2024" },
    { event: "Performance bonus", date: "15.03.2024" },
    { event: "Yearly salary raise", date: "01.12.2023" },
    { event: "Another example", date: "05.01.2024" },
    { event: "Another example", date: "10.01.2024" },
  ],
  currentSalary: "110k",
  salaryEffectiveDate: "01.12.2023",
};
