import { useState, useEffect } from "react";

import "./styles.scss";

import {
  fetchSingleTask,
  startCareerGrowth,
  fetchUsers,
} from "../../../../store";
import { setActiveViewData } from "../../../../store/pageViews/pageViewsSlice";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import { CalendarIcon } from "../../../../icons";
import { Button } from "../../../ui";
import { useNavigate } from "react-router-dom";

const PositionDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [doFetchSingleTask] = useThunk(fetchSingleTask);
  const [doStartCareerGrowth] = useThunk(startCareerGrowth);
  const [doFetchUsers] = useThunk(fetchUsers);

  const [allUsers, setAllUsers] = useState([]);
  const [goalData, setGoalData] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const singlePositionState = useSelector(
    (state) => state.positions
  )?.singlePosition;
  const matrixState = useSelector((state) => state.matrices)?.singleMatrix;
  const goalState = useSelector((state) => state.goals)?.selectedGoal;
  const usersState = useSelector((state) => state.users)?.data?.optionData;

  const progressReminderOptions = [
    { value: 1, label: "Every day" },
    { value: 2, label: "Every 3 days" },
    { value: 3, label: "Every 5 days" },
  ];

  const handleBack = () => {
    navigate("/");
  };

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleStartDate = (date) => {
    setGoalData({ ...goalData, startDate: moment(date).format("YYYY-MM-DD") });
  };

  const handleEndDate = (date) => {
    setGoalData({ ...goalData, endDate: moment(date).format("YYYY-MM-DD") });
  };

  const handleSelectTask = (task) => {
    doFetchSingleTask(task.id);
    navigate("/your-career/task-details");
    dispatch(
      setActiveViewData({
        type: "readonly",
      })
    );
  };

  const preselectedMentor = {
    label: `${goalState?.settings?.mentor?.first_name} ${goalState?.settings?.mentor?.last_name}`,
    value: goalState?.settings?.mentor?.id,
  };

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
    const reducedUsers = usersState?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [usersState]);

  const handleStartGrowth = () => {
    const settings = {
      mentorId: goalData?.mentorId || null,
      frequency: goalData?.frequency || null,
      startDate: goalData?.startDate || null,
      endDate: goalData?.endDate || null,
    };

    const payload = {
      userId: localUserData.id,
      careerPathId: matrixState.career_path.id,
      positionId: singlePositionState.id,
      settings,
    };
    doStartCareerGrowth(payload);
    handleBack();
  };

  return (
    <div className="career-vision-wrapper">
      <div className="career-header">
        <div className="header-title">
          <span onClick={handleBack}>Career Vision and Growth</span>
          <h2>{singlePositionState?.title}</h2>
        </div>
        <div className="header-actions">
          <Button handleClick={handleStartGrowth}>Start Career Growth</Button>
        </div>
      </div>
      <div className="career-position-content">
        <div className="position-details">
          <div className="details-header">
            <h3>Position Details</h3>
          </div>
          <div className="details-block">
            <label>Position title</label>
            <p>{singlePositionState?.title}</p>
          </div>
          <div className="details-block">
            <label>Position description</label>
            <p>{singlePositionState?.description}</p>
          </div>
          <div className="details-block">
            <label>Tasks</label>
            {!!singlePositionState?.tasks?.length ? (
              <ul>
                {singlePositionState?.tasks.map((task, index) => (
                  <li
                    key={index}
                    className="clickable-list-item"
                    onClick={() => handleSelectTask(task)}
                  >
                    {task.title}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Tasks</p>
            )}
          </div>
          <div className="details-block">
            <label>Resources</label>
            {!!singlePositionState?.resources?.length ? (
              <ul className="details-resources">
                {singlePositionState?.resources.map((resource, index) => (
                  <li key={index}>
                    <a
                      href={`${process.env.REACT_APP_BASE_API_URL}${resource.path}`}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      {resource.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Resources</p>
            )}
          </div>
          <div className="details-block">
            <label>Notes about position</label>
            <p>{singlePositionState?.notes}</p>
          </div>
        </div>
        <div className="task-settings">
          <div className="task-settings-wrapper">
            <h3>Goal Settings</h3>

            <div className="form-group">
              <label>Mentor</label>
              <DropdownSelect
                options={allUsers}
                handleSelect={handleChange}
                type="primary"
                name="mentorId"
                preselected={preselectedMentor}
              />
            </div>
            <div className="form-group date-picker-holder">
              <label>Task Start</label>
              <DatePicker
                name="startDate"
                selected={startDate}
                onChange={handleStartDate}
                dateFormat="dd.MM.yyyy"
                dateFormatCalendar={"MMM yyyy"}
                placeholderText={"Enter due date"}
                minDate={subMonths(new Date(), 256)}
                maxDate={addMonths(new Date(), 256)}
                value={
                  goalData?.startDate
                    ? moment(goalData?.startDate).format("DD.MM.yyyy")
                    : ""
                }
                showMonthDropdown
                showYearDropdown
              />
              <CalendarIcon />
            </div>
            <div className="form-group date-picker-holder">
              <label>Task End</label>
              <DatePicker
                name="endDate"
                selected={endDate}
                onChange={handleEndDate}
                dateFormat="dd.MM.yyyy"
                dateFormatCalendar={"MMM yyyy"}
                placeholderText="Enter due date"
                minDate={subMonths(new Date(), 256)}
                maxDate={addMonths(new Date(), 256)}
                value={
                  goalData?.endDate
                    ? moment(goalData?.endDate).format("DD.MM.yyyy")
                    : ""
                }
                showMonthDropdown
                showYearDropdown
              />

              <CalendarIcon />
            </div>

            <div className="form-group">
              <label>
                How often would you like to be reminded about progress?
              </label>
              <DropdownSelect
                options={progressReminderOptions}
                handleSelect={handleChange}
                type="primary"
                name="frequency"
                preselected={progressReminderOptions.find(
                  (item) => item.value === goalState?.settings?.frequency
                )}
              />
            </div>
            {/* <div className="task-settings-footer">
              <Button
                variant="primary"
                onClick={handleSaveGoal}
                disabled={
                  !goalData?.startDate ||
                  !goalData?.endDate ||
                  (!goalData?.mentor?.id && !goalData?.mentorId)
                }
              >
                Save
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionDetails;
