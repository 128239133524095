import { useEffect, useState } from "react";
import { CloseIcon } from "../../../../icons";
import { statuses } from "./options";
import moment from "moment/moment";

import { Button, Input } from "../../../ui";

import {
  fetchUsers,
  fetchTaskFeedbacks,
  createTaskFeedback,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";

import "./feedback.scss";
import UserIcon from "../../../ui/UserIcon/UserIcon";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../utils/textFormat";

const FeedbackTaskDetails = ({ selectedTask, handleActiveView }) => {
  const [feedback, setFeedback] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [doFetchTaskFeedbacks] = useThunk(fetchTaskFeedbacks);
  const [doCreateTaskFeedback] = useThunk(createTaskFeedback);
  const [doFetchUsers] = useThunk(fetchUsers);

  const taskFeedbacksState = useSelector(
    (state) => state.feedback
  )?.taskFeedbacks;
  const usersState = useSelector((state) => state.users)?.data?.optionData;

  useEffect(() => {
    if (selectedTask?.id) {
      doFetchTaskFeedbacks(selectedTask.id);
    }
    doFetchUsers({});
  }, []);

  useEffect(() => {
    if (!!taskFeedbacksState?.length) {
      setFeedbacks(taskFeedbacksState);
    }
  }, [taskFeedbacksState]);

  const handleChange = (event) => {
    const { value } = event.target ? event.target : event;
    setFeedback(value);
  };

  const saveFeedback = () => {
    if (!feedback) {
      return;
    }
    doCreateTaskFeedback({
      taskId: selectedTask?.id,
      userId: selectedTask?.user.id,
      content: feedback,
    });
    setFeedback("");
  };

  const types = [
    { value: 1, label: "Individual" },
    { value: 2, label: "Company" },
  ];

  const getUserData = (userId) => {
    if (!!usersState) {
      return usersState?.find((user) => user.id === userId);
    }
  };

  return (
    <div className="career-wrapper">
      <div className="career-header feedback-form-header">
        <div className="header-title">
          <p onClick={() => handleActiveView("feedback-list")}>Tasks</p>
          <h2>
            {selectedTask?.title}
            <div className="status-wrapper status-details">
              <span
                className={`status ${
                  selectedTask &&
                  statuses.find(
                    (status) => status.value === selectedTask?.status
                  ).color
                }`}
              >
                {selectedTask &&
                  statuses.find(
                    (status) => status.value === selectedTask?.status
                  ).label}
              </span>
            </div>
          </h2>
        </div>
      </div>
      <div className="career-feedback-grid">
        <div className="employee-section">
          <h3 className="details-item">Employee Details</h3>
          <div className="details-item flex-block">
            <label>Employee</label>
            <span>{selectedTask?.user?.name}</span>
          </div>
          <div className="details-item flex-block">
            <label>New position</label>
            <p>{selectedTask?.position}</p>
          </div>
        </div>

        <div className="feedback-section">
          <div className="feedback-wrapper">
            <h3>Task Feedback</h3>

            <form>
              <div className="career-form-group">
                <label>Feedback</label>
                <Input
                  type="textarea"
                  name="content"
                  value={feedback}
                  placeholder="Enter text"
                  handleChange={handleChange}
                />
              </div>
            </form>
            <div className="feedback-action">
              <Button handleClick={saveFeedback}>Save</Button>
            </div>
          </div>
        </div>

        <div className="task-section">
          <div className="task-section-wrapper">
            <h3 className="details-item">Task Details</h3>
            <div className="details-item flex-block">
              <label>Task title</label>
              <p>{selectedTask?.title}</p>
            </div>
            <div className="details-item flex-block">
              <label>Task description</label>
              <p>{selectedTask?.description}</p>
            </div>
            <div className="details-item flex-block">
              <label>Task Start</label>
              <p>{`${
                selectedTask?.startDate
                  ? moment(selectedTask?.startDate).format("DD-MM-YYYY")
                  : "/"
              }`}</p>
            </div>
            <div className="details-item flex-block">
              <label>Task End</label>
              <p>{`${
                selectedTask?.endDate
                  ? moment(selectedTask?.endDate).format("DD-MM-YYYY")
                  : "/"
              }`}</p>
            </div>
            <div className="details-item flex-block">
              <label>Task Type</label>
              <p>
                {
                  types.find(
                    (item) => item.value === parseInt(selectedTask?.type)
                  )?.label
                }
              </p>
            </div>
            <div className="details-item flex-block">
              <label>Assets</label>
              <ul>
                {selectedTask?.assets?.map((asset, index) => (
                  <li key={index}>
                    <a href="/">{asset}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="details-item flex-block">
              <label>Resources</label>
              <div className="file-wrapper">
                {!!selectedTask?.resources.length ? (
                  <ul className="selected-files">
                    {selectedTask?.resources?.map((resource, index) => (
                      <li key={index}>
                        {resource.title}
                        <span>
                          <CloseIcon />
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No resources</p>
                )}
              </div>
            </div>
            <div className="details-item flex-block">
              <label>Notes</label>
              <p>{selectedTask?.notes}</p>
            </div>
            <div className="details-item flex-block">
              <label>Mentor</label>
              <p>
                {selectedTask?.mentor
                  ? selectedTask?.mentor
                  : "No mentor selected"}
              </p>
            </div>
          </div>
        </div>

        {!!feedbacks?.length ? (
          <div className="task-feedbacks-section">
            <div className="feedback-wrapper">
              <h3 className="details-item">Task Feedbacks</h3>
              {feedbacks?.map((feedback, index) => (
                <div className="details-item flex-block" key={index}>
                  <label className="feedback-date-time">
                    {formatShortDate(
                      convertTextToDate(feedback?.createdAt),
                      "DD.MM.YYYY"
                    )}
                    <span>
                      {formatShortDate(
                        convertTextToDate(
                          feedback?.createdAt,
                          "DD.MM.YYYY hh:mm:ss"
                        ),
                        "HH:mm"
                      )}
                    </span>
                  </label>
                  <div className="feedback-content">
                    <p>{feedback?.content}</p>
                    <div className="feedback-user">
                      <UserIcon
                        userImage={getUserData(feedback?.userId)?.image}
                        userName={
                          getUserData(feedback?.userId)?.first_name ||
                          getUserData(feedback?.userId)?.name
                        }
                        iconStyle={{
                          marginRight: "10px",
                        }}
                      />
                      <p>
                        {`${getUserData(feedback?.userId)?.first_name} ${
                          getUserData(feedback?.userId)?.last_name
                        }`}
                        <span>{getUserData(1)?.position}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FeedbackTaskDetails;
