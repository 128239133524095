import { Table, UserIcon } from "../ui";

const EmployeeList = ({ employees }) => {
  const tableData = {
    rows: employees?.map((employee) => ({
      ...employee,
      userImage: (
        <UserIcon userImage={employee?.image} userName={employee?.name} />
      ),
    })),
    columns: [
      {
        id: 1,
        label: "User Image",
        slug: "userImage",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
      {
        id: 3,
        label: "Position",
        slug: "position",
      },
      {
        id: 4,
        label: "Superiors",
        slug: "manager",
      },
    ],
  };
  return (
    <Table
      rows={tableData?.rows}
      columns={tableData?.columns}
      hasActions={true}
      isAdmin={false}
    />
  );
};

export default EmployeeList;
