import React, { useState, useEffect } from "react";
import { ProgressBar, Table } from "../../../ui";
import { ClockIcon } from "../../../../icons";
import { setActiveViewData } from "../../../../store/pageViews/pageViewsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useThunk } from "../../../../hooks/useThunk";
import { fetchSurveys } from "../../../../store";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetSurveyMessage } from "../../../../store/surveys/surveysSlice";
import { useNavigate } from "react-router-dom";

const Completed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [foundData, setFoundData] = useState(null);

  const surveysState = useSelector((state) => state.surveys)?.surveys;
  const messageState = useSelector((state) => state.surveys)?.message;
  const searchTerm = useSelector((state) => state?.pageViews)?.searchTerm;

  const [doFetchSurveys] = useThunk(fetchSurveys);
  const [doShowToastMessage] = useToastMessage(resetSurveyMessage);

  useEffect(() => {
    dispatch(setActiveViewData([]));
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filtered = tableData?.rows.filter((data) =>
        data?.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFoundData(filtered);
      return;
    }
    setFoundData(null);
  }, [searchTerm]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: surveysState?.map((survey) => {
      return {
        ...survey,
        dueDate: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ClockIcon /> {survey?.dueDate}
          </div>
        ),
        progress: (
          <ProgressBar progress={survey?.percentageAnswered?.toFixed(0)} />
        ),
      };
    }),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "title",
      },
      {
        id: 3,
        label: "Description",
        slug: "description",
      },
      {
        id: 4,
        label: "Ending date",
        slug: "dueDate",
      },
      {
        id: 5,
        label: "Progress",
        slug: "progress",
      },
    ],
  };

  useEffect(() => {
    doFetchSurveys({ status: 2 });
  }, []);

  const handleViewSurvey = (row) => {
    const selectedRow = surveysState?.find(
      (survey) => survey?.id === row?.id
    )?.questions;

    navigate("/survey/overview");
    dispatch(setActiveViewData(selectedRow));
  };

  return (
    <>
      <Table
        rows={foundData || tableData?.rows}
        columns={tableData?.columns}
        handleRowClick={handleViewSurvey}
        emptyTableMessage="No Completed Surveys"
      />
      {/* <PaginationControl
        page={page}
        between={4}
        total={tableData.rows.length}
        limit={5}
        changePage={(page) => setPage(page)}
        ellipsis={1}
      /> */}
    </>
  );
};

export default Completed;
