import React, { useState, useEffect } from "react";
import { Button, Form, Input, Switcher } from "../../../../ui";
import DatePicker from "react-datepicker";
import { AddIcon, CalendarIcon, CloseIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createWorkshopSeminar,
  updateWorkshopSeminar,
} from "../../../../../store";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";
import { useDispatch } from "react-redux";

const WorkshopSeminars = ({ userId, editData }) => {
  const [doCreateWorkshopSeminar] = useThunk(createWorkshopSeminar);
  const [doUpdateWorkshopSeminar] = useThunk(updateWorkshopSeminar);
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();
  const [workshop, setWorkshop] = useState(null);
  const [topic, setTopic] = useState("");
  const [speaker, setSpeaker] = useState("");

  useEffect(() => {
    if (editData) {
      setWorkshop({
        title: editData?.name || "",
        host: editData?.host || "",
        location: editData?.location || "",
        startDate: String(editData?.start).split(" ")[0] || null,
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        topics: editData?.topics.map((topic) => topic?.title) || [],
        speakers: editData?.speakers.map((speaker) => speaker?.name) || [],
        type: editData?.type || "Workshop",
      });
    }
  }, [editData]);

  const handleIsSeminar = (_, value) => {
    const isSeminar = value ? "Seminar" : "Workshop";
    handleChange("type", isSeminar);
  };

  const handleLocation = (_, value) => {
    if (value) {
      handleChange("location", "Online");
    } else {
      workshop?.location && handleChange("location", "");
    }
  };

  const handleTopics = (e, operation, value = "") => {
    e.preventDefault();

    let topics = workshop?.topics || [];

    if (operation === "add" && !!topic) {
      topics = [...topics, topic];
      handleChange("topics", topics);
      setTopic("");
      return;
    }

    if (operation === "remove") {
      topics = topics?.filter((topic) => topic !== value);
      handleChange("topics", topics);
      return;
    }
  };

  const handleSpeakers = (e, operation, value = "") => {
    e.preventDefault();

    let speakers = workshop?.speakers || [];

    if (operation === "add" && !!speaker) {
      speakers = [...speakers, speaker];
      handleChange("speakers", speakers);
      setSpeaker("");
      return;
    }

    if (operation === "remove") {
      speakers = speakers?.filter((speaker) => speaker !== value);
      handleChange("speakers", speakers);
      return;
    }
  };

  const handleChange = (name, value) => {
    setWorkshop({ ...workshop, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!workshop.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!workshop.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!workshop.endDate || !workshop.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doCreateWorkshopSeminar({
      name: workshop?.title,
      location: workshop?.location,
      host: workshop?.host,
      type: workshop?.type || "Workshop",
      user_id: userId,
      start: workshop?.startDate + " 00:00:00",
      end: workshop?.endDate + " 00:00:00",
      speakers: workshop?.speakers?.map((speaker) => ({ name: speaker })),
      topics: workshop?.topics?.map((topic) => ({ title: topic })),
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!workshop.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!workshop.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }

    if (!workshop.endDate || !workshop.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateWorkshopSeminar({
      id: editData?.id,
      data: {
        name: workshop?.title,
        location: workshop?.location,
        host: workshop?.host,
        type: workshop?.type || "Workshop",
        start: workshop?.startDate + " 00:00:00",
        end: workshop?.endDate + " 00:00:00",
        speakers: workshop?.speakers?.map((speaker) => ({ name: speaker })),
        topics: workshop?.topics?.map((topic) => ({ title: topic })),
      },
    });
  };

  return (
    <div className="workshop-seminars">
      <Form>
        <Input
          label="Workshop / Seminar Name"
          placeholder={defaultPlaceholder}
          name="title"
          value={workshop?.title}
          handleChange={(e) => {
            e.preventDefault();
            handleChange(e?.target?.name, e?.target?.value);
          }}
        />
        <div className="form-group bottom-margin">
          <label>Select Type</label>
          <div className="form-row switcher-group">
            <label className="label-normal">Workshop</label>
            <Switcher
              customBackground="or-switch"
              checked={workshop?.type === "Seminar"}
              handleChange={handleIsSeminar}
            />
            <label className="label-normal">Seminar</label>
          </div>
        </div>
        <Input
          label="Host organization"
          placeholder={defaultPlaceholder}
          name="host"
          value={workshop?.host}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Conference Location</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Online</label>
              <Switcher
                checked={workshop?.location === "Online"}
                handleChange={handleLocation}
              />
            </div>
          </div>
          <Input
            placeholder={defaultPlaceholder}
            name="location"
            value={workshop?.location}
            disabled={workshop?.location === "Online"}
            handleChange={(e) => {
              e.preventDefault();
              handleChange(e?.target?.name, e?.target?.value);
            }}
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <div className="form-row">
            <div className="form-group date-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={convertTextToDate(workshop?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarIcon />
              </div>
            </div>
            <div className="form-group date-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={convertTextToDate(workshop?.endDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Workshop / Seminar  Topics"
              name="topics"
              placeholder={defaultPlaceholder}
              value={topic}
              handleChange={(e) => setTopic(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handleTopics(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {workshop?.topics?.length > 0 && (
            <ul className="topics-list">
              {workshop?.topics?.map((topic, index) => (
                <li key={`topic-${index}`}>
                  <p>{topic}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => handleTopics(e, "remove", topic)}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Speakers / Instructors"
              name="speakers"
              placeholder={defaultPlaceholder}
              value={speaker}
              handleChange={(e) => setSpeaker(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handleSpeakers(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {workshop?.speakers?.length > 0 && (
            <ul className="speakers-list">
              {workshop?.speakers?.map((speaker, index) => (
                <li key={`speaker-${index}`}>
                  <p>{speaker}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => handleSpeakers(e, "remove", speaker)}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default WorkshopSeminars;
