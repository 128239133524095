import { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import { Form, Input, Modal } from "../../../ui";
import { seniorityTypes } from "../../../../data/types";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";

import { CalendarIcon } from "../../../../icons";

import { useSelector } from "react-redux";
import { fetchPositions, fetchRoles, fetchTeams } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import {
  formatShortDate,
  convertTextToDate,
} from "../../../../utils/textFormat";

const UsersModal = ({ showModal, handleModalClose, handleSave }) => {
  const [user, setUser] = useState(null);
  const [dueDate, setDueDate] = useState(new Date());
  const [image, setImage] = useState(null);
  const [existingImage, setExistingImage] = useState(null);

  const [doFetchPositions] = useThunk(fetchPositions);
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doFetchRoles] = useThunk(fetchRoles);

  const formData = useSelector((state) => state.form)?.data;
  const formType = useSelector((state) => state.form)?.type;
  const positionsState = useSelector((state) => state.positions)?.data;
  const teamsState = useSelector((state) => state.teams)?.teams;
  const rolesState = useSelector((state) => state.roles)?.roles;
  const usersState = useSelector((state) => state.users)?.data;

  useEffect(() => {
    doFetchPositions();
    doFetchTeams({});
    doFetchRoles({ page: 1 });
  }, []);

  useEffect(() => {
    if (formData) {
      setUser(formData);
    }
  }, [formData]);

  const userActiveValues = [
    { value: "", label: "Select One" },
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const handleChange = (event, customName) => {
    if (customName) {
      setUser({ ...user, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    if (name === "phone") {
      setUser({ ...user, [name]: value.replace(/\D/g, "") });
      return;
    }
    setUser({ ...user, [name]: value });
  };

  const setUserBirthday = (date) => {
    setUser({ ...user, birthday: moment(date).format("DD.MM.yyy") });
  };

  const createImage = () => {
    const formData = new FormData();
    formData.append("resource", image);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingImage(data.data);
    });
  };

  useEffect(() => {
    if (!image) {
      return;
    }
    createImage();
  }, [image]);

  useEffect(() => {
    if (existingImage) {
      setUser({ ...user, image: existingImage?.id });
    }
  }, [existingImage]);

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const modalTitle = formType === "create" ? "Add User" : "Edit User";

  const selectedPosition = () => {
    let position = null;
    if (typeof user?.position === "number") {
      position = positionsState?.find((item) => item?.id === user?.position);
    } else {
      position = positionsState?.find((item) => item?.id === user?.position_id);
    }
    if (position) {
      return {
        value: position?.id,
        label: position?.title,
        isFixed: true,
      };
    }
    return "";
  };

  const selectedTeam = () => {
    let team = teamsState?.optionData?.find((item) => item?.id === user?.team);
    if (team) {
      return {
        value: team?.id,
        label: team?.name,
        isFixed: true,
      };
    }
    return "";
  };

  const selectedRole = () => {
    let role = rolesState?.allRoles?.find((item) => item?.id === user?.role);
    if (role) {
      return {
        value: role?.id,
        label: role?.name,
        isFixed: true,
      };
    }
    return "";
  };

  const selectedManager = () => {
    if (!!user?.manager?.first_name) {
      return {
        value: user?.manager?.id,
        label: `${user?.manager?.first_name} ${user?.manager?.last_name}`,
        isFixed: true,
      };
    }

    let manager = usersState?.optionData?.find(
      (item) => item?.id === user?.manager
    );
    if (manager) {
      return {
        value: manager?.id,
        label: `${manager?.first_name} ${manager?.last_name}`,
        isFixed: true,
      };
    }
    return "";
  };

  const changeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const handlePositionStartDateChannge = (startDate) => {
    setUser({
      ...user,
      position_start: formatShortDate(startDate, "DD.MM.yyyy HH:mm:ss"),
    });
  };

  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      title={modalTitle}
      handleConfirm={() => handleSave(user)}
      disabledConfirm={
        !user?.levelOfSeniority ||
        !user?.timeInPositionYears ||
        !user?.experienceYears
      }
      confirmButtonLabel="Save Changes"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <Input
            type="input"
            label="First name"
            placeholder="Enter first name"
            value={user?.first_name || ""}
            handleChange={handleChange}
            name="first_name"
          />
        </div>
        <div className="form-group">
          <Input
            type="input"
            label="Last name"
            placeholder="Enter last name"
            value={user?.last_name || ""}
            handleChange={handleChange}
            name="last_name"
          />
        </div>
        <div className="form-group">
          <Input
            type="email"
            label="Email address"
            placeholder="Enter email"
            value={user?.email || ""}
            handleChange={handleChange}
            name="email"
          />
        </div>
        <div className="form-group">
          <label>Role</label>
          <Select
            name="role"
            placeholder="Select Role"
            options={rolesState?.allRoles?.map((role) => ({
              value: role.id,
              label: role.name,
              isFixed: true,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleChange(event, "role")}
            value={selectedRole()}
          />
        </div>
        {formType !== "edit" && (
          <div className="form-group">
            <Input
              type="password"
              label="Password"
              placeholder="Enter password"
              handleChange={handleChange}
              name="password"
              value={user?.password || ""}
            />
          </div>
        )}
        <div className="form-group">
          <div className="custom-input">
            <Input
              type="file"
              label="Image"
              placeholder="Select a profile image to upload"
              name="image"
              handleChange={changeHandler}
            />
            <span>
              {existingImage?.title ||
                user?.image ||
                "Select a profile image to upload"}
            </span>
          </div>
        </div>
        <div className="form-group">
          <Input
            label="Phone"
            placeholder="Enter phone"
            value={user?.phone || ""}
            handleChange={handleChange}
            name="phone"
          />
        </div>
        <div className="date-picker-holder">
          <label>Birthday</label>
          <DatePicker
            selected={dueDate}
            onChange={setUserBirthday}
            dateFormat="dd.MM.yyyy"
            dateFormatCalendar={"MMM yyyy"}
            placeholderText="Enter birth date"
            minDate={subMonths(new Date(), 840)}
            maxDate={addMonths(new Date(), 0)}
            showMonthDropdown
            showYearDropdown
            value={user?.birthday}
          />
          <CalendarIcon />
        </div>
        <div className="form-group">
          <label>Job position</label>
          <Select
            name="position"
            placeholder="Select Position"
            options={positionsState?.map((position) => ({
              value: position?.id,
              label: position?.title,
              isFixed: true,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            value={selectedPosition()}
            onChange={(event) => handleChange(event, "position")}
          />
        </div>
        <div className="date-picker-holder">
          <label>Position Start</label>
          <DatePicker
            onChange={handlePositionStartDateChannge}
            selected={convertTextToDate(user?.position_start, "DD.MM.yyyy")}
            dateFormat="dd.MM.yyyy"
            dateFormatCalendar={"MMM yyyy"}
            placeholderText="Enter position start date"
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            disabled={
              typeof user?.position !== "number" ||
              user?.position_id === user?.position
            }
            value={
              formatShortDate(convertTextToDate(user?.position_start)) !==
              "Invalid date"
                ? formatShortDate(
                    convertTextToDate(user?.position_start),
                    "DD.MM.yyyy"
                  )
                : null
            }
          />
          <CalendarIcon />
        </div>
        <div className="form-group">
          <label>Team</label>
          <Select
            name="team"
            placeholder="Select Team"
            options={teamsState?.optionData?.map((team) => ({
              value: team?.id,
              label: team?.name,
              isFixed: true,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleChange(event, "team")}
            value={selectedTeam()}
          />
        </div>
        {formType === "edit" && !user ? (
          <></>
        ) : (
          <div className="form-group">
            <label>Active</label>
            <Select
              menuPlacement="top"
              name="active"
              placeholder="Select Active"
              options={userActiveValues}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) => handleChange(event, "active")}
              defaultValue={userActiveValues?.find(
                (item) => item?.value === user?.active
              )}
            />
          </div>
        )}
        <div className="form-group">
          <Input
            type="number"
            label="Experience In Years"
            placeholder="Enter experience"
            name="experienceYears"
            value={user?.experienceYears || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <Input
            type="number"
            label="Time In Position"
            placeholder="Enter time in position"
            name="timeInPositionYears"
            value={user?.timeInPositionYears || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group bottom-margin">
          <label>Level of seniority</label>
          <DropdownSelect
            options={seniorityTypes}
            handleSelect={handleChange}
            type="primary"
            name="levelOfSeniority"
            background="dark"
            preselected={{
              value: parseInt(user?.levelOfSeniority),
              label: seniorityTypes.find(
                (type) => type.value === parseInt(user?.levelOfSeniority)
              )?.label,
            }}
          />
        </div>
        <div className="form-group">
          <label>Manager</label>
          <Select
            name="manager"
            placeholder="Select Manager"
            options={usersState?.optionData?.map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
              isFixed: true,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleChange(event, "manager")}
            value={selectedManager()}
          />
        </div>
        {/* <Modal.Footer>
          <Button variant="secondary" handleClick={handleModalClose}>
            Close
          </Button>
          <Button
            handleClick={() => handleSave(user)}
            disabled={
              !user?.levelOfSeniority ||
              !user?.timeInPositionYears ||
              !user?.experienceYears
            }
          >
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Form>
    </Modal>
  );
};

export default UsersModal;
