import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Table, Filter, CardFilter, Status } from "../../../ui";
import { ClockIcon, SuccessIcon, TIcon } from "../../../../icons";
import { performanceSurveyStatuses } from "../../../../data/statuses";

import "./styles.scss";

const SurveysReview = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const surveysState = useSelector((state) => state?.surveys)?.surveys;

  const [surveys, setSurveys] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    setSurveys(surveysState);
  }, [surveysState]);

  useEffect(() => {
    if (selectedFilter !== null) {
      setSurveys(
        surveysState?.filter((survey) => survey?.status === selectedFilter)
      );
      return;
    }
    setSurveys(surveysState);
  }, [selectedFilter]);

  const surveyFinder = (survey) => {
    let currentStatus = performanceSurveyStatuses?.find(
      (status) => status?.value === survey?.status
    );
    return (
      <Status
        status={{ color: currentStatus?.color, label: currentStatus?.label }}
      />
    );
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };

  const handleResetFilter = () => {
    setSelectedFilter(null);
  };

  const surveysTableData = {
    rows: surveys.map((survey) => {
      return {
        ...survey,
        due_date: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ClockIcon />
            {survey?.dueDate}
          </div>
        ),
        progress: surveyFinder(survey),
      };
    }),
    columns: [
      {
        id: 1,
        label: "Title",
        slug: "title",
      },
      {
        id: 2,
        label: "Ending date",
        slug: "due_date",
      },
      {
        id: 3,
        label: "Progress",
        slug: "progress",
      },
      {
        id: 4,
        label: "",
        slug: "actions",
      },
    ],
  };

  const completedSurveys = () => {
    return surveys?.filter((survey) => survey?.percentageAnswered === 100);
  };

  const uncompletedSurveys = () => {
    return surveys?.filter((survey) => survey?.percentageAnswered < 100);
  };

  const pieData = {
    labels: [],
    datasets: [
      {
        label: "Number of Surveys",
        data: [completedSurveys()?.length, uncompletedSurveys()?.length],
        backgroundColor: ["#A9CE5B", "#F07171"],
        borderColor: ["#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
      },
    },
  };

  return (
    <>
      <div className="surveys">
        <div className="survey-table">
          <div className="title-line">
            <h3 className="title">Surveys</h3>
            <div className="filter-wrapper">
              <Filter
                filters={performanceSurveyStatuses}
                checked={selectedFilter}
                onRadioChange={handleFilterChange}
                onReset={handleResetFilter}
              />
            </div>
          </div>
          <Table
            rows={surveysTableData.rows}
            columns={surveysTableData.columns}
            emptyTableMessage="No Surveys"
          />
        </div>
        <div className="chart">
          <div className="card-filters">
            <CardFilter
              borderColor="#109095"
              backgroundColor="#14B4BA"
              textColor="#fff"
              title="Received Surveys"
              count={surveysState.length}
              icon={<TIcon />}
            />
            <CardFilter
              borderColor="#86A347"
              backgroundColor="#A9CE5B"
              textColor="#fff"
              title="Completed Surveys"
              count={completedSurveys()?.length}
              icon={<SuccessIcon />}
            />
            <CardFilter
              borderColor="#B35656"
              backgroundColor="#F07171"
              textColor="#fff"
              title="Uncompleted Surveys"
              count={uncompletedSurveys()?.length}
              icon={
                <div className="uncompleted">
                  <ClockIcon />
                </div>
              }
            />
          </div>
          <div className="pie">
            <h4>My survey stats</h4>
            <Pie data={pieData} options={pieOptions} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveysReview;
