import { useState, useEffect } from "react";
import { Offcanvas, Button, Table, UserInfo, Modal, Input } from "../../../ui";

import "../../styles.scss";
import "./position.scss";

import { SearchIcon, AddIcon, CloseIcon } from "../../../../icons";

import { columns } from "./data";

import {
  fetchPositions,
  fetchPositionEmployees,
  deletePosition,
  fetchSinglePosition,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import { resetSingleTask } from "../../../../store/tasks/tasksSlice";
import { setFormType } from "../../../../store/form/formSlice";

import EmployeesTable from "./EmployeesTable";
import { useNavigate } from "react-router-dom";

const PositionTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeUser, setActiveUser] = useState(null);
  const [positions, setPositions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEmployeesModal, setShowEmployeesModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(false);

  const [doFetchPositions] = useThunk(fetchPositions);
  const [doFetchPositionEmployees] = useThunk(fetchPositionEmployees);
  const [doDeletePosition] = useThunk(deletePosition);
  const [doFetchSinglePosition] = useThunk(fetchSinglePosition);

  const positionsState = useSelector((state) => state.positions)?.data;
  const positionEmployeesState = useSelector(
    (state) => state.positions
  )?.employees;

  useEffect(() => {
    dispatch(resetSingleTask());
  }, []);

  useEffect(() => {
    doFetchPositions();
  }, [doFetchPositions]);

  useEffect(() => {
    setPositions(positionsState);
  }, [positionsState]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const filterPositions = () => {
    const filteredPositions = positionsState?.filter((position) => {
      return position?.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setPositions(filteredPositions);
  };

  useEffect(() => {
    filterPositions();
  }, [searchTerm]);

  const handleShowEmployeesModal = (row) => {
    doFetchPositionEmployees(row.id);
    setShowEmployeesModal(true);
  };

  const handleCloseEmployeesModal = () => {
    setShowEmployeesModal(false);
  };

  const handleShowConfirmModal = (id) => {
    setSelectedRow(id);
    setShowConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleEditPosition = (id) => {
    handleActiveView("edit", id);
  };

  const handleAddPosition = () => {
    handleActiveView("create");
  };

  const handleDeletePosition = () => {
    doDeletePosition(selectedRow.id);
    const reducedPositions = positions.filter(
      (position) => position.id !== selectedRow.id
    );
    setPositions(reducedPositions);
    handleConfirmModalClose();
  };

  const handleProfileClose = () => {
    setShowUserInfo(false);
  };

  const tableData = {
    rows: positions.map((position) => {
      return {
        id: position?.id,
        title: position?.title,
        created_by: `${position.created_by?.firstName} ${position.created_by?.lastName}`,
        total_employees: position?.total_employees,
      };
    }),
    columns: columns,
  };

  const handleActiveView = (type, id) => {
    if (type === "create") {
      dispatch(setFormType("create"));
    } else {
      dispatch(setFormType("edit"));
      doFetchSinglePosition(id);
    }
    navigate("/career-path/position/form");
  };

  return (
    <div className="career-wrapper">
      <div className="career-header">
        <h2>Position</h2>
        <div className="header-actions">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              value={searchTerm}
              handleChange={handleSearch}
              icon={<SearchIcon />}
            />
          </div>
          <Button handleClick={() => handleAddPosition()}>
            <AddIcon />
            Add position
          </Button>
        </div>
      </div>
      <div className="career-content">
        <Table
          rows={tableData?.rows}
          columns={tableData?.columns}
          isAdmin={true}
          handleRowClick={(user) => handleShowEmployeesModal(user)}
          hasActions={true}
          handleEdit={(row) => {
            handleEditPosition(row?.id);
          }}
          handleDelete={handleShowConfirmModal}
        />

        <Modal
          show={showEmployeesModal}
          handleClose={handleCloseEmployeesModal}
          className="position-employees-modal"
          title="Employees"
          hasFooter={false}
        >
          <EmployeesTable employees={positionEmployeesState} />
        </Modal>
        <Modal
          show={showConfirmModal}
          handleClose={handleConfirmModalClose}
          title="Confirm delete"
          handleConfirm={handleDeletePosition}
        >
          <label>Are you sure?</label>
        </Modal>
        <Offcanvas
          show={showUserInfo}
          position="end"
          scroll={true}
          backdrop={true}
          onHide={handleProfileClose}
          className="user-info-offcanvas"
        >
          <UserInfo activeUser={activeUser} handleClose={handleProfileClose} />
        </Offcanvas>
      </div>
    </div>
  );
};

export default PositionTable;
