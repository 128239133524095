import { useEffect, useState } from "react";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { convertTextToDate, shortenText } from "../../../utils/textFormat";

import MiniMatrix from "./MiniMatrix";
import CareerGoalForm from "./forms/CareerGoalForm";
import { PathIcon } from "../../../icons";

import "./styles.scss";

import {
  fetchMatrixByPosition,
  fetchUsersGoals,
  fetchUsersTask,
  fetchFeedbacks,
  fetchUsersHighFive,
  fetchSingleUserTask,
} from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGoal } from "../../../store/goals/goalsSlice";
import {
  setSelectedTask,
  resetSingleTask,
} from "../../../store/tasks/tasksSlice";
import moment from "moment/moment";
import GiveFeedbackForm from "./forms/GiveFeedbackForm";
import RequestFeedbackForm from "./forms/RequestFeedbackForm";
import AnswerFeedbackForm from "./forms/AnswerFeedbackForm";
import PreviewFeedbackForm from "./forms/PreviewFeedbackForm";
import { UserIcon, Button } from "../../ui";
import { taskStatuses, goalStatuses } from "../../../data/statuses";
import { useNavigate } from "react-router-dom";

const CareerVision = () => {
  const navigate = useNavigate();
  const [localUserData, setLocalUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false);
  const [showRequestFeedbackModal, setShowRequestFeedbackModal] =
    useState(false);
  const [showAnswerFeedbackModal, setShowAnswerFeedbackModal] = useState(false);
  const [showPreviewFeedbackModal, setShowPreviewFeedbackModal] =
    useState(false);
  const [isSentSelected, setIsSentSelected] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [timelineGoalsLoaded, setTimelineGoalsLoaded] = useState(false);

  const dispatch = useDispatch();
  const [doFetchMatrix] = useThunk(fetchMatrixByPosition);
  const [doFetchUsersGoals] = useThunk(fetchUsersGoals);
  const [doFetchUsersTask] = useThunk(fetchUsersTask);
  const [doFetchFeedbacks] = useThunk(fetchFeedbacks);
  const [doFetchUsersHighFive] = useThunk(fetchUsersHighFive);
  const [doFetchSingleUserTask] = useThunk(fetchSingleUserTask);

  const matrixState = useSelector((state) => state.matrices)?.singleMatrix;
  const goalsState = useSelector((state) => state.goals)?.usersGoals;
  const usersTasksState = useSelector((state) => state.tasks)?.usersTasks;
  const feedbacksState = useSelector((state) => state.feedback)?.feedbacks;
  const highFivesState = useSelector(
    (state) => state.highFives
  )?.usersHighFives;

  useEffect(() => {
    const userDataFromLocalStorage = JSON.parse(
      localStorage.getItem("authUserData")
    );
    setLocalUserData(userDataFromLocalStorage);

    if (userDataFromLocalStorage) {
      if (userDataFromLocalStorage?.position_id) {
        doFetchMatrix({
          positionId: userDataFromLocalStorage?.position_id,
          userId: userDataFromLocalStorage?.id,
        });
      }

      doFetchUsersTask(userDataFromLocalStorage?.id);
      doFetchFeedbacks(userDataFromLocalStorage?.id);
      doFetchUsersHighFive({ userId: userDataFromLocalStorage?.id });
    }
    dispatch(setSelectedTask(null));
    dispatch(resetSingleTask());
  }, []);

  useEffect(() => {
    const userDataFromLocalStorage = JSON.parse(
      localStorage.getItem("authUserData")
    );
    doFetchUsersGoals(userDataFromLocalStorage?.id);
  }, [usersTasksState]);

  useEffect(() => {
    const userDataFromLocalStorage = JSON.parse(
      localStorage.getItem("authUserData")
    );
    doFetchUsersTask(userDataFromLocalStorage?.id);
    doFetchUsersGoals(userDataFromLocalStorage?.id);
  }, [matrixState]);

  const pendingFeedbacks = feedbacksState.filter(
    (feedback) => feedback?.status === 0
  );
  const finishedFeedbacks = feedbacksState.filter(
    (feedback) => feedback?.status === 1 || feedback?.status === 2
  );

  // TODO: Create dateTime function in utils
  let timelineGoals = goalsState
    .map((goal) => {
      if (!!goal?.settings?.startDate && !!goal?.settings?.endDate) {
        const startDate = moment(goal?.settings?.startDate, "DD.MM.YYYY");
        const endDate = moment(goal?.settings?.endDate, "DD.MM.YYYY");

        let startMonth = startDate.format("M") - 1;
        let startDay = startDate.format("D");
        let startYear = startDate.format("YYYY");

        let endMonth = endDate.format("M") - 1;
        let endDay = endDate.format("D");
        let endYear = endDate.format("YYYY");

        return {
          id: goal.id,
          name: goal.name,
          start: new Date(startYear, startMonth, startDay),
          end: new Date(endYear, endMonth, endDay),
          type: "task",
          progress: 100,
          isDisabled: true,
          status: goal.type,
          styles: {
            progressColor: goal.type === 1 ? "#8B5CF6" : "#0ea5e9",
            progressSelectedColor: goal.type === 1 ? "#8B5CF6" : "#0ea5e9",
          },
        };
      }
      return null;
    })
    .filter((goal) => goal !== null);

  const feedbacksStatuses = [
    {
      id: 0,
      label: "Pending",
    },
    {
      id: 1,
      label: "Received",
    },
    {
      id: 2,
      label: "Sent",
    },
  ];

  const handleSelectTask = (task) => {
    doFetchSingleUserTask(task.id);
    navigate("/your-career/task-details");
  };

  const handleSelectGoal = (goal) => {
    dispatch(setSelectedGoal(goal));
    navigate("/your-career/goal-details");
  };

  const handleShowHighFive = () => {
    navigate("/your-career/high-fives");
  };

  const handleOpenGoalModal = () => {
    setShowModal(true);
  };

  const handleCloseGoalModal = () => {
    setSelectedTask(null);
    resetSingleTask();
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowGiveFeedbackModal(false);
    setShowRequestFeedbackModal(false);
    setShowAnswerFeedbackModal(false);
    setShowPreviewFeedbackModal(false);
  };

  const handleSelectFinishedFeedback = (feedback) => {
    setShowPreviewFeedbackModal(true);
    setSelectedFeedback(feedback);
  };

  const handleGiveFeedbackClick = () => {
    setIsSentSelected(false);
    setShowGiveFeedbackModal(true);
  };

  const handleSelectPendingRequest = (feedback) => {
    setSelectedFeedback(feedback);
    setShowAnswerFeedbackModal(true);
  };

  const isGoalCompleted = (goal) => {
    return (
      !!goal?.tasks?.length && !goal?.tasks.some((task) => task.status !== 100)
    );
  };

  const individualTasks = usersTasksState?.filter((task) => task.type === 1);
  const businessTasks = usersTasksState?.filter((task) => task.type === 2);

  useEffect(() => {
    if (!!timelineGoals.length) {
      setTimelineGoalsLoaded(true);
    }
  }, [goalsState]);

  useEffect(() => {
    if (!!timelineGoalsLoaded) {
      const originalTextElements = document.querySelectorAll("text._2q1Kt");
      if (originalTextElements.length > 0) {
        const numberOfCopies = 2; // Adjust the number of copies as needed
        const incrementX = 400; // Adjust the increment as needed
        originalTextElements.forEach((originalText) => {
          for (let i = 1; i <= numberOfCopies; i++) {
            const newText = originalText.cloneNode(true);
            const newX =
              parseFloat(originalText.getAttribute("x")) +
              (i === 1 ? -incrementX : incrementX);
            newText.setAttribute("x", newX);
            originalText.parentNode.appendChild(newText);
          }
        });
      }
    }
  }, [timelineGoalsLoaded]);
  // TODO: move this to datetime utils
  const formatShortDate = (date) => {
    return moment(date, "DD.MM.YYYY");
  };

  const dummyTask = () => {
    const currentYear = moment().year();
    const selectedMonths = [11];

    return selectedMonths.map((month) => {
      const start = moment([currentYear + 1, month, 1]);
      const end = moment(start).endOf("month");

      return {
        id: start.format("MMM").toLowerCase(),
        name: "",
        start: start.toDate(),
        end: end.toDate(),
        styles: {
          backgroundColor: "transparent",
          backgroundSelectedColor: "transparent",
        },
        isDisabled: true,
        progress: 0,
      };
    });
  };

  return (
    <>
      <div className="career-vision-wrapper">
        <div className="career-header">
          <h2>Career Vision and Growth</h2>
          <div className="header-actions">
            <Button handleClick={handleOpenGoalModal}>
              Create Your Own Career Goal
            </Button>
          </div>
        </div>
        <div className="career-vision-content">
          <div className="career-vision-left">
            <div className="career-block career-growth">
              <h3>My growth</h3>
              <div className="gantt-wrapper">
                {!!timelineGoals.length ? (
                  <Gantt
                    tasks={[...timelineGoals, ...dummyTask()]}
                    viewMode="Month"
                    barCornerRadius={15}
                    columnWidth={100}
                  />
                ) : (
                  <div>No tasks started</div>
                )}
              </div>
              {!!timelineGoals.length && (
                <div className="gantt-legend">
                  <p>Individual</p>
                  <p>Business</p>
                </div>
              )}
            </div>
            <div className="career-block career-goals career-block-list">
              <h3>Goals</h3>
              {!goalsState.length ? (
                <div className="career-no-goals">
                  <div className="goals-icon">
                    <PathIcon />
                  </div>
                  <h4>No goals created/assigned</h4>
                  <p>
                    Create your own goal or start career growth for certain
                    position from vision board.
                  </p>
                  <Button handleClick={handleOpenGoalModal}>
                    Create Your Own Career Goal
                  </Button>
                </div>
              ) : (
                <ul className="scrollable-block">
                  {goalsState.map((goal, index) => (
                    <li
                      className="block-list-item"
                      key={index}
                      onClick={() => handleSelectGoal(goal)}
                    >
                      <h5>
                        {goal.name}
                        {!!isGoalCompleted(goal) && <span>Completed</span>}
                      </h5>

                      <>
                        {goal?.settings?.startDate ? (
                          <p className="block-date">
                            {`${moment(
                              formatShortDate(goal?.settings?.startDate)
                            ).format("MMM DD")} - ${moment(
                              formatShortDate(goal?.settings?.endDate)
                            ).format("MMM DD")}`}
                          </p>
                        ) : (
                          <p className="block-date">Goal not started</p>
                        )}
                      </>

                      <div className="status-wrapper">
                        <p
                          className={`status ${
                            goalStatuses.find((item) => item.id === goal.type)
                              ?.color
                          }`}
                        >
                          {
                            goalStatuses.find((item) => item.id === goal.type)
                              ?.label
                          }
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {!!individualTasks?.length && (
              <div className="career-block career-tasks career-block-list">
                <h3>Your Tasks</h3>
                <ul className="scrollable-block">
                  {individualTasks?.map((task, index) => (
                    <li
                      className="block-list-item"
                      key={index}
                      onClick={() => handleSelectTask(task)}
                    >
                      <h5>{task?.title}</h5>
                      {task?.settings?.startDate ? (
                        <p className="block-date">
                          {`${moment(
                            convertTextToDate(
                              task?.settings?.startDate,
                              "DD.MM.yyyy",
                              false
                            )
                          ).format("MMM DD")} - ${moment(
                            convertTextToDate(
                              task?.settings?.endDate,
                              "DD.MM.yyyy",
                              false
                            )
                          ).format("MMM DD")}`}
                        </p>
                      ) : (
                        <p className="block-date">Task not started</p>
                      )}
                      <div className="status-wrapper">
                        <p
                          className={`status ${
                            taskStatuses.find((item) => item.id === task.status)
                              ?.color
                          }`}
                        >
                          {
                            taskStatuses.find((item) => item.id === task.status)
                              ?.label
                          }
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {!!businessTasks?.length && (
              <div className="career-block career-tasks career-block-list">
                <h3>Business Tasks</h3>
                <ul className="scrollable-block">
                  {businessTasks?.map((task, index) => (
                    <li
                      className="block-list-item"
                      key={index}
                      onClick={() => handleSelectTask(task)}
                    >
                      <h5>{task?.title}</h5>
                      {task?.settings?.startDate ? (
                        <p className="block-date">
                          {`${moment(
                            convertTextToDate(
                              task?.settings?.startDate,
                              "DD.MM.yyyy",
                              false
                            )
                          ).format("MMM DD")} - ${moment(
                            convertTextToDate(
                              task?.settings?.endDate,
                              "DD.MM.yyyy",
                              false
                            )
                          ).format("MMM DD")}`}
                        </p>
                      ) : (
                        <p className="block-date">Task not started</p>
                      )}
                      <div className="status-wrapper">
                        <p
                          className={`status ${
                            taskStatuses.find((item) => item.id === task.status)
                              ?.color
                          }`}
                        >
                          {
                            taskStatuses.find((item) => item.id === task.status)
                              ?.label
                          }
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="career-block career-feedback">
              <h3>Feedback</h3>
              <h4>Be proactive and ask for feedback!</h4>
              <p>
                Why wait around for feedback when research shows it is more
                effective to ask? Everyones perspective is subjective so it is
                important to ask for feedback from multiple people to get full,
                unbiased view.
              </p>
              <div className="button-wrapper">
                <Button handleClick={() => setShowRequestFeedbackModal(true)}>
                  Request feedback
                </Button>
                <Button handleClick={handleGiveFeedbackClick}>
                  Give feedback
                </Button>
              </div>
              <div className="career-block-list">
                <ul className="scrollable-block">
                  {finishedFeedbacks?.map((feedback, index) => (
                    <li
                      className="block-list-item"
                      onClick={() => handleSelectFinishedFeedback(feedback)}
                      key={index}
                    >
                      {feedback?.status === 1 && (
                        <h5>Feedback from {feedback?.sender?.name}</h5>
                      )}
                      {feedback?.status === 2 && (
                        <h5>Feedback sent to {feedback?.receiver?.name}</h5>
                      )}

                      <p className="block-date">{feedback?.createdAt}</p>
                      <div className="status-wrapper">
                        <p className="status purple">
                          {
                            feedbacksStatuses.find(
                              (item) => item.id === feedback.status
                            )?.label
                          }
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="feedback-pending">
                <h4>Pending requests ({pendingFeedbacks.length})</h4>
                <p>Requests to give feedback from your peers.</p>
                <div className="career-block-list">
                  <ul className="scrollable-block">
                    {pendingFeedbacks?.map((feedback, index) => (
                      <li
                        className="block-list-item"
                        key={index}
                        onClick={() => handleSelectPendingRequest(feedback)}
                      >
                        <h5>
                          Feedback required by{" "}
                          {feedback?.sender?.id === localUserData?.id
                            ? feedback?.receiver?.name
                            : feedback?.sender?.name}
                        </h5>
                        <p className="block-date">{feedback?.createdAt}</p>
                        <div className="status-wrapper">
                          <p className="status purple">
                            {
                              feedbacksStatuses.find(
                                (item) => item.id === feedback.status
                              )?.label
                            }
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="career-vision-right">
            <div className="career-block career-board">
              <h3>Vision Board</h3>
              {!!matrixState ? (
                <MiniMatrix user={localUserData} matrix={matrixState} />
              ) : (
                <p className="no-matrix">There is no matching career path</p>
              )}
            </div>

            <div className="career-block career-high-fives">
              <h3>High Fives</h3>
              <div className="scrollable-block">
                {highFivesState?.highFives?.map((highFive, index) => (
                  <div className="high-five-message-wrapper" key={index}>
                    <div className="user-images">
                      <UserIcon
                        userImage={highFive.sender.image}
                        userName={highFive.sender.name}
                        iconStyle={{
                          width: "40px",
                          height: "40px",
                          fontSize: "30px",
                        }}
                      />
                      <UserIcon
                        userImage={highFive.receiver.image}
                        userName={highFive.receiver.name}
                        iconStyle={{
                          width: "40px",
                          height: "40px",
                          fontSize: "30px",
                        }}
                      />
                    </div>
                    <div className="high-five-message">
                      <p>
                        <b>{highFive.sender.name}</b> to{" "}
                        <b>{highFive.receiver.name}</b>
                      </p>
                      <p className="message">
                        {shortenText(highFive.message, 100)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <Button handleClick={handleShowHighFive}>High five!</Button>
            </div>
          </div>
        </div>
        {showModal && (
          <CareerGoalForm
            showModal={showModal}
            handleCloseGoalModal={handleCloseGoalModal}
          />
        )}
        {showGiveFeedbackModal && (
          <GiveFeedbackForm
            showGiveFeedbackModal={showGiveFeedbackModal}
            handleModalClose={handleModalClose}
            isSent={isSentSelected}
            selectedFeedback={selectedFeedback}
          />
        )}
        {showRequestFeedbackModal && (
          <RequestFeedbackForm
            showRequestFeedbackModal={showRequestFeedbackModal}
            handleModalClose={handleModalClose}
            selectedFeedback={selectedFeedback}
          />
        )}
        {showAnswerFeedbackModal && (
          <AnswerFeedbackForm
            showAnswerFeedbackModal={showAnswerFeedbackModal}
            handleModalClose={handleModalClose}
            selectedFeedback={selectedFeedback}
          />
        )}
        {showPreviewFeedbackModal && (
          <PreviewFeedbackForm
            showPreviewFeedbackModal={showPreviewFeedbackModal}
            handleModalClose={handleModalClose}
            selectedFeedback={selectedFeedback}
          />
        )}
      </div>

      {/* <PositionDetails />
      <TaskDetails />
      <GoalDetails /> */}
    </>
  );
};

export default CareerVision;
