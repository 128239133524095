import "./styles.scss";

const ProgressBar = ({ progress }) => {
  const extractPercentage = (input) => {
    if (typeof input === "string" && input.includes("%")) {
      const percentage = parseFloat(input.replace("%", ""));
      return !isNaN(percentage) ? percentage : null;
    }
    return input;
  };

  const reducedProgress = extractPercentage(progress);

  const progressColor = () => {
    if (reducedProgress < 30) {
      return "red";
    }
    if (reducedProgress >= 30 && reducedProgress < 70) {
      return "orange";
    }
    return "green";
  };

  return (
    <div className="progress-wrapper">
      <div className="progress-bar">
        <span
          className={`progress-scale ${progressColor()}`}
          style={{ width: reducedProgress ? `${reducedProgress}%` : "0%" }}
        ></span>
      </div>
      <p style={reducedProgress === 0 ? { color: "#000" } : {}}>
        {reducedProgress || 0}%
      </p>
    </div>
  );
};

export default ProgressBar;
