import { useEffect, useState, useRef } from "react";
import InitiativeReview from "./InitiativeReview";
import CompensationReview from "./CompensationReview";
import {
  fetchMatrixByPosition,
  fetchUserCareerPath,
  fetchCareerPathHistory,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

const InitiativeAndCompensation = ({ isExpanded, selectedUser }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  const [doFetchMatrix] = useThunk(fetchMatrixByPosition);
  const [doFetchCareerPathHistory] = useThunk(fetchCareerPathHistory);
  const [doFetchUserCareerPath] = useThunk(fetchUserCareerPath);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
      doFetchMatrix({
        positionId: selectedUser?.active_position || selectedUser?.position_id,
        userId: selectedUser?.id,
      });
      doFetchUserCareerPath(selectedUser?.id);
      doFetchCareerPathHistory(selectedUser?.id);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <InitiativeReview selectedUser={selectedUser} />
        <hr />
        <CompensationReview />
      </div>
    </div>
  );
};

export default InitiativeAndCompensation;
