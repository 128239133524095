import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import { AddIcon, CloseIcon, DatePickerIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createConferenceAttendance,
  updateConferenceAttendance,
} from "../../../../../store";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const ConferenceAttendance = ({ userId, editData }) => {
  const inputPlaceholder = "Type here...";
  const [doCreateConferenceAttendance] = useThunk(createConferenceAttendance);
  const [doUpdateConferenceAttendance] = useThunk(updateConferenceAttendance);
  const dispatch = useDispatch();
  const [conference, setConference] = useState({});
  const [speaker, setSpeaker] = useState("");
  const [topic, setTopic] = useState("");

  useEffect(() => {
    if (editData) {
      setConference({
        title: editData?.name,
        location: editData?.location,
        startDate: editData?.start,
        endDate: editData?.end,
        speakers: editData?.speakers?.map((speaker) => speaker?.name),
        topics: editData?.topics?.map((topic) => topic?.title),
        notes: editData?.notes,
      });
    }
  }, [editData]);

  const handleLocation = (_, value) => {
    if (value) {
      handleChange("location", "Online");
    } else {
      conference?.location && handleChange("location", "");
    }
  };

  const handleTopics = (e, operation, value = "") => {
    e.preventDefault();

    let topics = conference?.topics || [];

    if (operation === "add" && !!topic) {
      topics = [...topics, topic];
      handleChange("topics", topics);
      setTopic("");
      return;
    }

    if (operation === "remove") {
      topics = topics?.filter((topic) => topic !== value);
      handleChange("topics", topics);
      return;
    }
  };

  const handleSpeakers = (e, operation, value = "") => {
    e.preventDefault();

    let speakers = conference?.speakers || [];

    if (operation === "add" && !!speaker) {
      speakers = [...speakers, speaker];
      handleChange("speakers", speakers);
      setSpeaker("");
      return;
    }

    if (operation === "remove") {
      speakers = speakers?.filter((speaker) => speaker !== value);
      handleChange("speakers", speakers);
      return;
    }
  };

  const handleChange = (name, value) => {
    setConference({ ...conference, [name]: value });
  };

  const handleSpeakerChange = (value) => {
    setSpeaker(value);
  };

  const handleTopicChange = (value) => {
    setTopic(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!conference.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!conference.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!conference.endDate || !conference.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doCreateConferenceAttendance({
      name: conference?.title,
      location: conference?.location,
      user_id: userId,
      start: conference?.startDate,
      end: conference?.endDate,
      speakers: conference?.speakers?.map((speaker) => ({ name: speaker })),
      topics: conference?.topics?.map((topic) => ({ title: topic })),
      notes: conference?.notes,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!conference.title && !editData?.name) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!conference.location && !editData?.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (
      (!conference.endDate || !conference.startDate) &&
      (!editData?.end || !editData?.start)
    ) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateConferenceAttendance({
      id: editData.id,
      data: {
        name: conference.title,
        location: conference.location,
        user_id: userId,
        start: conference.startDate,
        end: conference.endDate,
        speakers: conference.speakers.map((speaker) => ({ name: speaker })),
        topics: conference.topics.map((topic) => ({ title: topic })),
        notes: conference.notes,
      },
    });
  };

  return (
    <div className="conference-attendance">
      <Form>
        <Input
          label="Conference Name"
          placeholder={inputPlaceholder}
          name="title"
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
          value={conference?.title}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Conference Location</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Online</label>
              <Switcher
                name="locationOnline"
                checked={conference?.location === "Online"}
                handleChange={handleLocation}
              />
            </div>
          </div>
          <Input
            placeholder={inputPlaceholder}
            name="location"
            handleChange={(e) =>
              handleChange(e?.target?.name, e?.target?.value)
            }
            value={conference?.location}
            disabled={conference?.location === "Online"}
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <div className="form-row">
            <div className="form-group date-group">
              <label className="label-normal">Start date</label>
              <div className="date-picker-holder">
                <DatePicker
                  onChange={(e) =>
                    handleChange(
                      "startDate",
                      formatShortDate(e, "DD.MM.YYYY HH:mm:ss")
                    )
                  }
                  dateFormat={"dd.MM.yyyy"}
                  showMonthDropdown
                  showYearDropdown
                  selected={convertTextToDate(conference?.startDate)}
                />
                <DatePickerIcon />
              </div>
            </div>
            <div className="form-group date-group">
              <label className="label-normal">End date</label>
              <div className="date-picker-holder">
                <DatePicker
                  onChange={(e) =>
                    handleChange(
                      "endDate",
                      formatShortDate(e, "DD.MM.YYYY HH:mm:ss")
                    )
                  }
                  dateFormat={"dd.MM.yyyy"}
                  showMonthDropdown
                  showYearDropdown
                  selected={convertTextToDate(conference?.endDate)}
                />
                <DatePickerIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Speakers / Presenters"
              placeholder={inputPlaceholder}
              name="speakers"
              handleChange={(e) => handleSpeakerChange(e?.target?.value)}
              value={speaker}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handleSpeakers(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {conference?.speakers?.length > 0 && (
            <ul className="speakers-list">
              {conference?.speakers?.map((speaker, index) => (
                <li key={`speaker-${index}`}>
                  <span>{speaker}</span>
                  <Button
                    variant="control hidden"
                    buttonStyles={{
                      width: "18px",
                      height: "18px",
                      border: "1px solid #a1a1a1",
                      padding: "0",
                    }}
                    handleClick={(e) => handleSpeakers(e, "remove", speaker)}
                  >
                    <CloseIcon fill="#a1a1a1" width="5px" height="5px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Conference Topics"
              placeholder={inputPlaceholder}
              name="topics"
              handleChange={(e) => handleTopicChange(e?.target?.value)}
              value={topic}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handleTopics(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {conference?.topics?.length > 0 && (
            <ul className="topics-list">
              {conference?.topics?.map((topic, index) => (
                <li key={`topic-${index}`}>
                  <p>{topic}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => handleTopics(e, "remove", topic)}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Input
          type="textarea"
          label="Notes"
          name="notes"
          placeholder={inputPlaceholder}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
          value={conference?.notes || ""}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ConferenceAttendance;
