import { Status, TableList, TrendCard } from "../../ui";
import DashboardView from "./DashboardView";
import "./styles.scss";
import DashboardForm from "./DashboardForm";
import { useLocation, useNavigate } from "react-router-dom";
import DraftsView from "./DraftsView";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useThunk } from "../../../hooks/useThunk";
import { fetchCompletedReviews } from "../../../store";
import moment from "moment/moment";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const recentViewsColumns = [
    { id: 1, slug: "info" },
    { id: 2, slug: "status" },
  ];

  const [doFetchCompletedReviews] = useThunk(fetchCompletedReviews);

  const assignedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.assigned;
  const completedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.completed;
  const notScheduledState = useSelector(
    (state) => state?.users
  )?.userReviewTemplate;
  const managedUsersState = useSelector((state) => state?.users)?.data;

  const [assignedReviews, setAssignedReviews] = useState(
    assignedReviewsState?.length || 0
  );
  const [notScheduledReviews, setNotScheduledReviews] = useState(
    notScheduledState?.length || 0
  );
  const [overdueReviews, setOverdueReviews] = useState(0);
  const [completedReviews, setCompletedReviews] = useState(
    completedReviewsState || []
  );

  useEffect(() => {
    doFetchCompletedReviews({ managedUsers: managedUsersState });
  }, []);

  useEffect(() => {
    const separatedReviews = {
      overdue: [],
      upcoming: [],
    };
    assignedReviewsState?.map((review) => {
      const date = moment(review?.schedule_date, "DD.MM.YYYY HH:mm:ss");
      if (!date.isAfter(moment())) {
        return (separatedReviews.overdue = [
          ...separatedReviews.overdue,
          review,
        ]);
      } else {
        return (separatedReviews.upcoming = [
          ...separatedReviews.upcoming,
          review,
        ]);
      }
    });
    setOverdueReviews(separatedReviews?.overdue?.length || 0);
    setAssignedReviews(separatedReviews?.upcoming?.length || 0);
  }, [assignedReviewsState]);

  useEffect(() => {
    setNotScheduledReviews(notScheduledState?.length || 0);
  }, [notScheduledState]);

  useEffect(() => {
    setCompletedReviews(
      completedReviewsState?.map((review) => ({
        id: review?.id,
        info: (
          <div>
            <strong>{`${review?.user?.first_name} ${review?.user?.last_name}`}</strong>
            {/* <p>Position and seniority</p> */}
          </div>
        ),
        status: (
          <div className="dashboard-status-wrapper">
            <Status status={{ label: "Completed", color: "green" }} />
          </div>
        ),
      })) || []
    );
  }, [completedReviewsState]);

  const handleBack = () => {
    navigate("/personal-performance/dashboard");
  };

  const calculatePercentage = (part, total) => {
    return Math.round((part / total) * 100);
  };

  return (
    <div className="dashboard-wrapper">
      <h2 className="p-6">
        {location.pathname === "/personal-performance/form" && (
          <span className="back-arrow" onClick={handleBack}></span>
        )}
        Personal Performance Dashboard
      </h2>
      <div className="dashboard-flex-content">
        {location.pathname === "/personal-performance/dashboard" && (
          <DashboardView />
        )}
        {location.pathname === "/personal-performance/form" && (
          <DashboardForm />
        )}
        {location.pathname === "/personal-performance/drafts" && <DraftsView />}
        <div className="dashboard-flex-item w-40">
          <div className="card-filter-wrapper">
            <TrendCard
              label="Not scheduled-total"
              count={notScheduledReviews}
              percentage={calculatePercentage(
                notScheduledReviews,
                notScheduledReviews + assignedReviews
              )}
            />
            <TrendCard
              label="Ouverdue-total"
              count={overdueReviews}
              percentage={notScheduledReviews}
            />
            <TrendCard
              label="Upcoming-total"
              count={assignedReviews}
              percentage={calculatePercentage(
                assignedReviews,
                notScheduledReviews + assignedReviews
              )}
            />
          </div>
          <div className="dashboard-block">
            <TableList
              title="Recent views"
              rows={completedReviews}
              columns={recentViewsColumns}
              listKey="recent-views"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
