import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { removeToastMessage } from "../../../store/toastMessages/toastMessagesSlice";
import Button from "../Button";

import {
  CloseIcon,
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  InfoIcon,
} from "../../../icons";

const Toast = ({ toastList = [], duration }) => {
  const dispatch = useDispatch();

  const [pauseActive, setPauseActive] = useState(false);
  const [notificationTimer, setNotificationTimer] = useState(duration);
  // const [icon, setIcon] = useState();
  const [closingToast, setClosingToast] = useState("");

  useEffect(() => {
    if (toastList.length > 0) {
      timer();
    }
  }, [notificationTimer, pauseActive, toastList]);

  const iconType = (type) => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningIcon />;
      case "info":
        return <InfoIcon />;
      default:
        return null;
    }
  };

  if (!Array.isArray(toastList) && toastList.length === 0) {
    console.error("Toast component must receive array");
    return null;
  }

  const deleteToast = (index) => {
    dispatch(removeToastMessage(index));
  };

  const timer = () => {
    if (duration !== 0) {
      setTimeout(() => {
        if (notificationTimer > 0 && !pauseActive) {
          setNotificationTimer(notificationTimer - 1000);
        }
      }, 1000);

      if (notificationTimer <= 1000) {
        setClosingToast("closing");
      } else {
        setClosingToast("");
      }

      if (notificationTimer === 0) {
        deleteToast(0);
        setNotificationTimer(duration);
      }
    }
  };

  return (
    <div
      className="toaster-wrapper"
      onMouseEnter={() => setPauseActive(true)}
      onMouseLeave={() => setPauseActive(false)}
    >
      {toastList.map((toast, i) => (
        <div
          key={i}
          type={toast?.type}
          className={`toaster ${i === 0 ? closingToast : ""}`}
        >
          <div className="flex">
            <div className="icon">{iconType(toast?.type)}</div>
            <div className="toaster-message">
              <p className="title">{toast?.title}</p>
              <p className="text">{toast?.message}</p>
            </div>
          </div>
          <Button
            handleClick={() => deleteToast(i)}
            buttonStyles={{
              width: "15px",
              height: "15px",
              padding: "2px",
              background: "none",
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default Toast;
