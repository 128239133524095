import { useEffect, useState } from "react";

import {
  fetchInterests,
  createInterest,
  updateInterest,
  deleteInterest,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Pagination, Table } from "../../../ui";
import InterestsModal from "./InterestsModal";
import { resetInterestMessage } from "../../../../store/interests/interestsSlice";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Interests = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);

  const [doFetchInterests] = useThunk(fetchInterests);
  const [doDeleteInterest] = useThunk(deleteInterest);
  const [doCreateInterest, isLoading] = useThunk(createInterest);
  const [doUpdateInterest] = useThunk(updateInterest);
  const [doShowToastMessage] = useToastMessage(resetInterestMessage);

  const interestsState = useSelector((state) => state.interests)?.interests;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.interests)?.message;
  const formTypeState = useSelector((state) => state?.form)?.type;

  const [selectedInterest, setSelectedInterest] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      doFetchInterests({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchInterests]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: interestsState?.map((interest) => ({
      ...interest,
    })),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
    ],
  };

  const handleEdit = (interest) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(interest));
    dispatch(setActiveModal("Interests-modal"));
  };
  const handleDelete = () => {
    doDeleteInterest(selectedInterest?.id);
    handleCloseConfirmationModal();
  };
  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (interest) => {
    if (formTypeState === "edit") {
      doUpdateInterest(interest);
      handleModalClose();
      return;
    }
    doCreateInterest(interest);
    handleModalClose();
  };

  const handleShowConfirmationModal = (interest) => {
    setSelectedInterest(interest);
    dispatch(setActiveModal("confirmation-modal"));
  };

  const handleCloseConfirmationModal = () => {
    setSelectedInterest(null);
    dispatch(setActiveModal(""));
  };

  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleShowConfirmationModal}
        hasActions={true}
      />

      {activeModalState === "Interests-modal" ? (
        <InterestsModal
          showModal={activeModalState === "Interests-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        ""
      )}
      <Modal
        title="Delete Interest"
        show={activeModalState === "confirmation-modal"}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={handleDelete}
      >
        <p>Are you sure you want to delete this interest?</p>
      </Modal>
    </div>
  );
};

export default Interests;
