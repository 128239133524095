import React, { useState, useEffect } from "react";
import "./Header.scss";
import {
  CloseIcon,
  HamburgerIcon,
  ReportsIcon,
  WorkHistoryIcon,
  CareerIcon,
  NotificationActiveIcon,
} from "../../icons";
import {
  UserIcon,
  Offcanvas,
  Button,
  UserInfo,
  OutsideClick,
  Input,
} from "../ui";
import { useStateValue } from "../../StateProvider";
import { getAuth, signOut } from "firebase/auth";
import Select from "react-select";
import { Link, useNavigate, useLocation } from "react-router-dom";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import { useThunk } from "../../hooks/useThunk";
import { fetchSingleUser, updateUserAvailability } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { resetLoggedInUser } from "../../store/users/usersSlice";
import DailyPulse from "../DailyPulse/DailyPulse";
import titles from "../../routes/titles";
import { Modal } from "../ui";

function Header({ screenWidth, handleResponsiveMenuToggle }) {
  const dispatch = useDispatch();
  const [{ user }] = useStateValue();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const location = useLocation();
  const currentLocation = `/${location.pathname.split("/").filter(Boolean)[0]}`;
  const title = titles[currentLocation];
  const [showList, setShowList] = useState(false);
  const [showUpdateStatusForm, setShowUpdateStatusForm] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [unreadGroupMessages, setUnreadGroupMessages] = useState([]);
  const [unreadPrivateMessages, setUnreadPrivateMessages] = useState([]);
  const [showEmojis, setShowEmojis] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const userState = useSelector((state) => state?.users)?.singleUser;
  const [userAvailability, setUserAvailability] = useState({
    userId: user?.id || localUserData?.id,
    availability: null,
    status: null,
    customText: "",
    customEmoji: {},
  });

  const [doUpdateUserAvailability] = useThunk(updateUserAvailability);
  const [doFetchSingleUser] = useThunk(fetchSingleUser);

  const logo = "/logo/logo.png";
  const logoText = "/logo/logo-text.png";

  useEffect(() => {
    if (user?.id) {
      doFetchSingleUser(user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (!!userState?.online && !!userState?.status) {
      const updatedUserData = {
        ...localUserData,
        online: userState?.online,
        status: userState?.status,
      };

      localStorage.setItem("authUserData", JSON.stringify(updatedUserData));
    }
  }, [userState]);

  useEffect(() => {
    if (
      userAvailability?.availability === 1 &&
      userAvailability?.status === 1
    ) {
      setUserAvailability({ ...userAvailability, status: null });
    }
  }, [userAvailability]);

  const navigate = useNavigate();

  const ExitApp = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.removeItem("authUser");
        localStorage.removeItem("authUserData");
        localStorage.removeItem("last_active_date");
        dispatch(resetLoggedInUser());
        navigate("/login");
        window.location.reload();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getUnread = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/chat/unread/` + localUserData?.id
      );
      const chats = await res.json();
      setUnreadGroupMessages(
        chats.groups.map((res) => ({
          id: res.id,
          uuid: res.uuid,
          name: res.name,
          type: res.type,
          public: res.public,
        }))
      );
      setUnreadPrivateMessages(
        chats.private.map((private_chat) => ({
          id: private_chat.id,
          name: private_chat.name,
          uuid: private_chat.uuid,
          uid: private_chat.uuid.split("/")[0].replace(user.uid, ""),
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const intervalCall = setInterval(() => {
      getUnread();
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const handleShowList = () => {
    setShowList(!showList);
  };

  const handleCloseUpdateStatus = (event) => {
    setShowUpdateStatusForm(false);
    setShowProfile(false);
  };

  const statusOptions = [
    { value: 1, label: "Available" },
    { value: 2, label: "On vacation" },
    { value: 3, label: "Busy" },
    { value: 4, label: "Sick" },
    { value: 5, label: "Custom" },
  ];
  const onlineOptions = [
    { value: 1, label: "Offline" },
    { value: 2, label: "Online" },
  ];

  const validate = () => {
    if (
      userAvailability?.availability !== null ||
      userAvailability?.status !== null
    ) {
      return 2;
    } else {
      return 1;
    }
  };

  const updateStatus = (event) => {
    event.preventDefault();
    doUpdateUserAvailability(userAvailability);
    setShowUpdateStatusForm(false);
  };

  const handleStatusClick = () => {
    setShowUpdateStatusForm(true);
  };

  const handleNotificationShow = () => {
    setShowNotifications(!showNotifications);
  };

  const handleShowProfile = () => {
    setShowProfile(true);
  };
  const handleProfileClose = () => {
    setShowProfile(false);
  };

  const handleSelectSettings = () => {
    navigate(`/settings`);
    handleProfileClose();
  };

  const handleSelect = () => {
    setShowNotifications(false);
  };

  const handleOpenEmojis = (e) => {
    e.preventDefault();
    setShowEmojis(true);
  };

  const handleCloseEmojis = () => {
    setShowEmojis(false);
  };

  const handleHideUserActions = () => {
    setShowList(false);
  };

  const dotColor = () => {
    if (localUserData?.online === 2) {
      switch (localUserData?.status) {
        case 1:
          return "green-dot";
        case 2:
          return "yellow-dot";
        case 3:
          return "red-dot";
        case 4:
          return "red-dot";
        case 5:
          return "yellow-dot";
        default:
          return "red-dot";
      }
    }
    return "gray-dot";
  };

  const handleAvailabilityOptionChange = (e, name) => {
    if (name === "customEmoji") {
      setUserAvailability({ ...userAvailability, [name]: e });
      setSelectedEmoji(e);
      return;
    }
    setUserAvailability({ ...userAvailability, [name]: e.value });
  };

  return (
    <div className="header">
      <Offcanvas
        show={showProfile}
        position="end"
        scroll={true}
        backdrop={true}
        onHide={handleProfileClose}
        className="user-info-offcanvas"
      >
        <UserInfo activeUser={localUserData} handleClose={handleProfileClose} />
      </Offcanvas>
      <div className="header-main">
        <div className="header-title">
          {screenWidth <= 840 && (
            <div
              className="toggle-responsive-menu"
              onClick={handleResponsiveMenuToggle}
            >
              <HamburgerIcon />
            </div>
          )}
          {screenWidth > 475 && <h2>{title}</h2>}
        </div>
        <DailyPulse />
        <div className="header-notification">
          <div className="notification-icon" onClick={handleNotificationShow}>
            <NotificationActiveIcon />
          </div>
          {showNotifications && (
            <OutsideClick onOutsideClick={() => setShowNotifications(false)}>
              <div className="header-notification-list">
                {unreadPrivateMessages?.map((listItem) => (
                  <Link to={`/chat/${listItem.uuid}`}>
                    <div
                      className="notification-list-item"
                      onClick={() => handleSelect(listItem)}
                    >
                      <span className="notification-icon-default">
                        {listItem?.name[0]}
                      </span>
                      <p>{listItem.name}</p>
                      <span>New Message</span>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="header-notification-list">
                {unreadGroupMessages?.map((listItem) => (
                  <Link to={`/chat/${listItem.uuid}`}>
                    <div
                      className="notification-list-item"
                      onClick={() => handleSelect(listItem)}
                    >
                      {listItem.icon}
                      <div>
                        <p>{listItem.name}</p>
                        <span>{listItem.date}</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </OutsideClick>
          )}
        </div>
      </div>
      <Modal
        show={showUpdateStatusForm}
        handleClose={handleCloseUpdateStatus}
        className="availability-modal"
        title={
          screenWidth <= 375 ? (
            <>
              <img src={logo} alt="logo-text" />
              <img src={logoText} alt="logo-text" />
            </>
          ) : (
            "Set availability"
          )
        }
        disabledConfirm={!validate()}
        handleConfirm={(event) => updateStatus(event)}
      >
        <form>
          {screenWidth <= 375 && <h3>Set availability</h3>}
          <div className="form-group">
            {screenWidth > 375 && <label>Set yourself as </label>}
            <Select
              name="online"
              options={onlineOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                handleAvailabilityOptionChange(e, "availability")
              }
              value={onlineOptions.find(
                (option) => option.value === userAvailability?.availability
              )}
              required
              placeholder={screenWidth <= 375 ? "Set yourself as" : "Select"}
            />
          </div>
          <div className="form-group">
            {screenWidth > 375 && <label>Set Status </label>}
            <Select
              name="status"
              options={
                // userOnline?.value === 0
                userAvailability?.availability === 1
                  ? statusOptions.filter((option) => option?.value !== 1)
                  : statusOptions
              }
              className="basic-multi-select"
              classNamePrefix="select"
              value={statusOptions.find(
                (option) => option.value === userAvailability?.status
              )}
              onChange={(e) => handleAvailabilityOptionChange(e, "status")}
              placeholder={screenWidth <= 375 ? "Set Status" : "Select"}
              required
            />
          </div>
          {/* {userStatus?.label === "Custom" && ( */}
          {userAvailability?.status === 5 && (
            <div className="form-group emoji-form-group">
              <Input
                name="customText"
                type="textarea"
                placeholder="Custom status..."
                handleChange={(e) =>
                  handleAvailabilityOptionChange(e, "customText")
                }
              />

              <div className="tab-content button-input">
                <span>{selectedEmoji?.native}</span>
                <Button handleClick={handleOpenEmojis}>Add Emoji</Button>
              </div>
              {showEmojis && (
                <OutsideClick onOutsideClick={handleCloseEmojis}>
                  <Picker
                    data={data}
                    onEmojiSelect={(emoji) =>
                      handleAvailabilityOptionChange(emoji, "customEmoji")
                    }
                    theme="light"
                  />
                </OutsideClick>
              )}
            </div>
          )}
        </form>
      </Modal>
      <div className="header-user">
        <OutsideClick onOutsideClick={() => setShowList(false)}>
          <div className="user-wrapper" onClick={handleShowList}>
            <UserIcon
              userImage={localUserData?.image || localUserData?.photoUrl}
              userName={localUserData?.first_name}
              iconStyle={{
                width: "50px",
                height: "50px",
                fontSize: "30px",
              }}
            />
            {screenWidth <= 767 && (
              <span className={`responsive-dot ${dotColor()}`}></span>
            )}
            {screenWidth > 767 && (
              <div className="header-user-text">
                <h4>{`${localUserData?.first_name} ${localUserData?.last_name}`}</h4>
                <p className={dotColor()}>
                  {localUserData?.online === 0 && localUserData?.status === 0
                    ? "Offline"
                    : statusOptions.find(
                        (option) => option?.value === localUserData?.status
                      )?.label}
                </p>
              </div>
            )}
            {showList && screenWidth > 375 && (
              <ul className="profile-options">
                <li onClick={handleStatusClick}>Update your status</li>
                <li onClick={handleShowProfile}>Profile</li>
                <li onClick={handleSelectSettings}>Settings</li>
                <li onClick={ExitApp}>Sign out</li>
              </ul>
            )}
            {screenWidth <= 375 && (
              <Offcanvas
                show={showList}
                position="end"
                scroll={true}
                onHide={handleHideUserActions}
                className="user-actions-_offcanvas"
              >
                <div className="user-actions-header">
                  <div className="logo-wrapper">
                    <img src={logo} alt="logo" />
                    <img src={logoText} alt="logo-text" />
                    <div
                      className="modal-header-close"
                      onClick={() => setShowList(false)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <div className="user-actions-body">
                  <h4>Profile Settings</h4>
                  <ul className="responsive-profile-options">
                    <li onClick={handleShowProfile}>
                      <CareerIcon />
                      <span>Profile</span>
                    </li>
                    <li onClick={handleSelectSettings}>
                      <ReportsIcon />
                      <span>Settings</span>
                    </li>
                    <li onClick={handleStatusClick}>
                      <WorkHistoryIcon />
                      <span>Set availability</span>
                    </li>
                  </ul>
                  <p onClick={ExitApp}>Sign out</p>
                </div>
              </Offcanvas>
            )}
          </div>
        </OutsideClick>
      </div>
      {screenWidth <= 475 && (
        <div className="header-responsive-title">
          <h2>{title}</h2>
        </div>
      )}
    </div>
  );
}

export default Header;
