import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { AddIcon, CloseIcon, DatePickerIcon } from "../../../../../icons";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createPublicSpeaking,
  updatePublicSpeaking,
} from "../../../../../store";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const PublicSpeaking = ({ userId, editData }) => {
  const [doCreatePublicSpeaking] = useThunk(createPublicSpeaking);
  const [doUpdatePublicSpeaking] = useThunk(updatePublicSpeaking);
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();
  const [speaking, setSpeaking] = useState({});
  const [topic, setTopic] = useState("");

  useEffect(() => {
    if (editData) {
      setSpeaking({
        title: editData?.name,
        location: editData?.location,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        notes: editData?.notes,
        topics: editData?.topics.map((topic) => topic.title),
      });
    }
  }, [editData]);

  const handleLocation = (_, value) => {
    if (value) {
      handleChange("location", "Online");
    } else {
      speaking?.location && handleChange("location", "");
    }
  };

  const handleTopics = (e, operation, value = "") => {
    e.preventDefault();

    let topics = speaking?.topics || [];

    if (operation === "add" && !!topic) {
      topics = [...topics, topic];
      handleChange("topics", topics);
      setTopic("");
      return;
    }

    if (operation === "remove") {
      topics = topics?.filter((topic) => topic !== value);
      handleChange("topics", topics);
      return;
    }
  };

  const handleChange = (name, value) => {
    setSpeaking({ ...speaking, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!speaking.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!speaking.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!speaking.endDate || !speaking.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doCreatePublicSpeaking({
      name: speaking?.title,
      location: speaking?.location,
      start: speaking?.startDate + " 00:00:00",
      end: speaking?.endDate + " 00:00:00",
      notes: speaking?.notes,
      user_id: userId,
      topics: speaking?.topics?.map((topic) => ({ title: topic })),
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!speaking.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!speaking.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!speaking.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!speaking.endDate || !speaking.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdatePublicSpeaking({
      id: editData?.id,
      data: {
        name: speaking?.title,
        location: speaking?.location,
        start: speaking?.startDate + " 00:00:00",
        end: speaking?.endDate + " 00:00:00",
        notes: speaking?.notes,
        topics: speaking?.topics?.map((topic) => ({ title: topic })),
        user_id: userId,
      },
    });
  };

  return (
    <div className="public-speaking">
      <Form>
        <Input
          name="title"
          placeholder={defaultPlaceholder}
          label="Event name"
          value={speaking?.title}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Event Location</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Online</label>
              <Switcher
                checked={speaking?.location === "Online"}
                handleChange={handleLocation}
              />
            </div>
          </div>
          <Input
            name="location"
            placeholder={defaultPlaceholder}
            value={speaking?.location}
            disabled={speaking?.location === "Online"}
            handleChange={(e) =>
              handleChange(e?.target?.name, e?.target?.value)
            }
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <div className="form-row">
            <div className="form-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={convertTextToDate(speaking?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <DatePickerIcon />
              </div>
            </div>
            <div className="form-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={convertTextToDate(speaking?.endDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <DatePickerIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              placeholder={defaultPlaceholder}
              label="Event Topics"
              value={topic}
              handleChange={(e) => setTopic(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => handleTopics(e, "add")}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {speaking?.topics?.length > 0 && (
            <ul className="topics-list">
              {speaking?.topics?.map((topic, index) => (
                <li key={`topic-${index}`}>
                  <p>{topic}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => handleTopics(e, "remove", topic)}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Input
          type="textarea"
          name="notes"
          label="Notes"
          placeholder={defaultPlaceholder}
          value={speaking?.notes}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default PublicSpeaking;
