import { useState, useEffect } from "react";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import TaskForm from "./TaskForm";
import { Button, Form, Input } from "../../../ui";

import {
  createPosition,
  updatePosition,
  fetchUsers,
  fetchSingleTask,
  deleteTask,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

import "../../styles.scss";
import "./position.scss";

import { PlusIcon, DeleteIcon, CloseIcon } from "../../../../icons";

import { useStateValue } from "../../../../StateProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PositionForm = () => {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [taskFormType, setTaskFormType] = useState("");
  const [positionData, setPositionData] = useState({
    id: 1,
    creator: user.id,
    title: "",
    description: "",
    tasks: [],
    resources: [],
  });

  const usersState = useSelector((state) => state.users)?.data?.optionData;
  const singlePositionState = useSelector(
    (state) => state.positions
  )?.singlePosition;
  const singleTaskState = useSelector((state) => state.tasks)?.singleTask;
  const formType = useSelector((state) => state.form)?.type;

  const [doCreatePositions] = useThunk(createPosition);
  const [doUpdatePositions] = useThunk(updatePosition);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchSingleTask, isLoading] = useThunk(fetchSingleTask);
  const [doDeleteTask] = useThunk(deleteTask);

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
    const reducedUsers = usersState?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [usersState]);

  const handleSave = () => {
    if (formType === "create") {
      doCreatePositions({
        ...positionData,
        tasks: positionData.tasks.map((task) => task.id),
      });
    } else {
      doUpdatePositions({
        ...positionData,
        tasks: positionData.tasks.map((task) => task.id),
      });
    }

    navigate("/career-path/position/table");
  };

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setPositionData({
      ...positionData,
      [name]: value,
    });
  };

  const handleOpenTaskModal = (type, id) => {
    if (type === "edit") {
      doFetchSingleTask(id);
    }
    setTaskFormType(type);
    setShowModal(true);
  };

  const handleCloseTaskModal = () => {
    setShowModal(false);
  };

  const changeHandler = (event) => {
    setAttachment(event.target.files);
  };

  const createAttachment = () => {
    const formData = new FormData();
    formData.append("resource", attachment[0]);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingAttachments([...existingAttachments, data.data]);
    });
  };

  useEffect(() => {
    if (!attachment.length) {
      return;
    }
    createAttachment();
  }, [attachment]);

  useEffect(() => {
    const fileCollection = existingAttachments.map((file) => file.id);
    setPositionData({ ...positionData, resources: fileCollection });
  }, [existingAttachments]);

  useEffect(() => {
    if (!!singleTaskState?.id) {
      const tasksIds = positionData?.tasks.map((task) => task.id);
      if (!tasksIds.includes(singleTaskState?.id)) {
        setPositionData({
          ...positionData,
          tasks: [...positionData?.tasks, singleTaskState],
        });
        return;
      }
      const reducedTasks = positionData?.tasks.map((task) => {
        if (task.id === singleTaskState.id) {
          return singleTaskState;
        }
        return task;
      });
      setPositionData({
        ...positionData,
        tasks: reducedTasks,
      });
    }
  }, [singleTaskState]);

  useEffect(() => {
    if (formType === "edit" && singlePositionState) {
      setPositionData({
        ...positionData,
        id: singlePositionState?.id,
        title: singlePositionState?.title,
        description: singlePositionState?.description,
        experience: singlePositionState?.experience,
        tasks: singlePositionState?.tasks,
        resources: singlePositionState?.resources,
      });
      if (singlePositionState.resources) {
        setExistingAttachments([
          ...existingAttachments,
          ...singlePositionState.resources,
        ]);
      }
    }
  }, [singlePositionState]);

  const handleRemoveFile = (id) => {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/${id}`,
      requestOptions
    );
    const reducedFiles = existingAttachments.filter((file) => file.id !== id);
    setExistingAttachments(reducedFiles);
  };

  const handleDeleteTask = (e, id) => {
    e.preventDefault();
    doDeleteTask(id);
    const reducedTasks = positionData.tasks.filter((task) => task.id !== id);
    setPositionData({ ...positionData, tasks: reducedTasks });
  };

  return (
    <div className="career-wrapper">
      <div className="career-header position-form-header">
        <div className="header-title">
          <span onClick={() => navigate("/career-path/position/table")}>
            Positions
          </span>
          <h2>New Position Title</h2>
        </div>
        <div className="header-actions">
          <Button handleClick={handleSave}>Save</Button>
        </div>
      </div>
      <div className="career-position-form">
        <Form>
          <h3>Position Details</h3>
          <div className="career-form-group">
            <label>Position Title</label>
            <Input
              type="input"
              label="Position title"
              placeholder="Enter title"
              name="title"
              value={positionData?.title || ""}
              handleChange={handleChange}
            />
          </div>
          <div className="career-form-group">
            <label>Position description</label>
            <Input
              type="textarea"
              placeholder="Enter text"
              name="description"
              value={positionData?.description || ""}
              handleChange={handleChange}
              inputStyles={{ maxWidth: "460px" }}
            />
          </div>

          <div className="career-form-group mentor-select">
            <label>Position experience</label>
            <Input
              type="number"
              placeholder="Years of experience"
              name="experience"
              value={positionData?.experience || ""}
              handleChange={handleChange}
            />
          </div>

          <div className="career-form-group">
            <label>Tasks</label>
            <div className="career-add-wrapper">
              {!!positionData?.tasks?.length && (
                <ul>
                  {positionData?.tasks?.map((task, index) => (
                    <li key={index}>
                      <span
                        onClick={() => handleOpenTaskModal("edit", task.id)}
                      >
                        {task?.title}
                      </span>
                      <Button
                        variant="action"
                        handleClick={(e) => handleDeleteTask(e, task.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
              <div
                className="career-add"
                onClick={() => handleOpenTaskModal("create")}
              >
                <PlusIcon />
                <span>Add task</span>
              </div>
            </div>
          </div>

          {showModal && (
            <TaskForm
              showModal={showModal}
              handleCloseTaskModal={handleCloseTaskModal}
              taskFormType={taskFormType}
              isTaskLoading={isLoading}
            />
          )}

          <div className="career-form-group">
            <label>Resources</label>
            <div className="file-wrapper">
              {!!existingAttachments.length && (
                <ul className="selected-files">
                  {existingAttachments?.map((resource, index) => (
                    <li key={index}>
                      <a
                        href={`${process.env.REACT_APP_BASE_API_URL}${resource.path}`}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        {resource?.title}
                      </a>

                      <span onClick={() => handleRemoveFile(resource.id)}>
                        <CloseIcon />
                      </span>
                    </li>
                  ))}
                </ul>
              )}
              <div className="file-select">
                {!positionData?.resources?.length && (
                  <div className="file-select-empty">Add resource</div>
                )}
                <label>
                  <PlusIcon />
                  Add another resource
                </label>
                <input
                  type="file"
                  name="image"
                  multiple
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PositionForm;
