import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ProgressBar } from "../../../ui";
import MiniMatrix from "../../../CareerPath/client/MiniMatrix";
import "./styles.scss";
import moment from "moment/moment";

const InitiativeReview = ({ selectedUser }) => {
  const [userCareerUpdatedData, setUserCareerUpdatedData] = useState(null);
  const [updatedHistory, setUpdatedHistory] = useState([]);
  const matrixState = useSelector((state) => state.matrices)?.singleMatrix;
  const userCareerPathState = useSelector(
    (state) => state.matrices
  )?.userCareerPath;
  const careerPathHistoryState = useSelector(
    (state) => state.matrices
  )?.careerPathHistory;

  useEffect(() => {
    if (userCareerPathState) {
      const updatedUserCareerPath = {
        ...userCareerPathState,
        started_at: userCareerPathState?.started_at
          ? moment(
              userCareerPathState?.started_at,
              "DD.MM.YYYY HH:mm:ss"
            ).format("DD.MM.YYYY")
          : null,
        deadline: userCareerPathState?.deadline
          ? moment(userCareerPathState?.deadline, "DD.MM.YYYY HH:mm:ss").format(
              "DD.MM.YYYY"
            )
          : null,
      };
      setUserCareerUpdatedData(updatedUserCareerPath);
    }
  }, [userCareerPathState]);

  useEffect(() => {
    if (!!careerPathHistoryState) {
      const updatedHistory = careerPathHistoryState?.map((item) => {
        return {
          ...item,
          started_at: item?.started_at
            ? moment(item?.started_at, "DD.MM.YYYY HH:mm:ss").format(
                "DD.MM.YYYY"
              )
            : null,
          deadline: item?.deadline
            ? moment(item?.deadline, "DD.MM.YYYY HH:mm:ss").format("DD.MM.YYYY")
            : null,
        };
      });

      setUpdatedHistory(updatedHistory);
    }
  }, [careerPathHistoryState]);

  const renderList = (type) => {
    return (
      <ul>
        <li>
          <div className="head column">
            {type === "current" ? "Current path" : "Career path history"}
          </div>
          <div className="head column">
            {type === "current" ? "Goal Position" : "Position"}
          </div>
          <div className="head column">Start date</div>
          <div className="head column">
            {type === "current" ? "Deadline" : "Achieved"}
          </div>
          <div className="head column">Progress</div>
        </li>
        {type === "current" ? (
          <li>
            <div className="column">
              <p className="name">{userCareerUpdatedData?.user_name}</p>
              <p>{selectedUser?.position}</p>
            </div>
            <div className="column">
              {userCareerPathState?.active_position_name}
            </div>
            <div className="column">{userCareerUpdatedData?.started_at}</div>
            <div className="column">{userCareerUpdatedData?.deadline}</div>
            <div className="column">
              <ProgressBar progress={userCareerUpdatedData?.progress} />
            </div>
          </li>
        ) : (
          updatedHistory?.map((path, index) => (
            <li key={`history-${index}`}>
              <div className="column">
                <p className="name">{path?.user_name}</p>
              </div>
              <div className="column">{path?.position_name}</div>
              <div className="column">{path?.started_at}</div>
              <div className="column">{path?.deadline}</div>
              <div className="column">
                <ProgressBar progress={path?.progress} />
              </div>
            </li>
          ))
        )}
      </ul>
    );
  };

  return (
    <div className="initiative-review">
      <div className="initiative">
        <div className="title-line">
          <h2 className="title single-view-header-filters">
            Career path initiative
          </h2>
        </div>
        <div className="current-path-list">{renderList("current")}</div>
        <div className="career-history-list">
          {!!updatedHistory?.length ? renderList("history") : ""}
        </div>
      </div>
      <div className="graph">
        {!!matrixState ? (
          <MiniMatrix user={selectedUser?.id} matrix={matrixState} />
        ) : (
          <p className="no-matrix">There is no matching career path</p>
        )}
      </div>
    </div>
  );
};

export default InitiativeReview;
