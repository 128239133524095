import React, { useEffect, useState } from "react";
import { conferenceModalViews } from "../../../../../data/views";
import { Checkbox, Modal, Button } from "../../../../ui";
import ConferenceAttendance from "./ConferenceAttendance";
import "./styles.scss";
import WorkshopSeminars from "./WorkshopSeminars";
import PublicSpeaking from "./PublicSpeaking";
import PanelDiscussions from "./PanelDiscussions";
import ProfessionalOrganizations from "./ProfessionalOrganizations";
import CommitteeInvolvement from "./CommitteeInvolvement";
import AdvancedDegrees from "./AdvancedDegrees";
import CertificationPrograms from "./CertificationPrograms";
import { useDispatch, useSelector } from "react-redux";
import { resetSingleConferenceAttendance } from "../../../../../store/conferenceAttendance/conferenceAttendanceSlice";
import { resetSingleWorkshopSeminar } from "../../../../../store/workshopSeminar/workshopSeminarSlice";
import { resetSinglePublicSpeaking } from "../../../../../store/publicSpeaking/publicSpeakingSlice";
import { resetSinglePanelDiscussion } from "../../../../../store/panelDiscussion/panelDiscussionSlice";
import { resetSingleAdvancedDegree } from "../../../../../store/advancedDegree/advancedDegreeSlice";
import { resetSingleProfessionalOrganization } from "../../../../../store/professionalOrganization/professionalOrganizationSlice";
import { resetSingleCommitteeInvolvement } from "../../../../../store/committeeInvolvement/committeeInvolvementSlice";
import { resetSingleCertificationProgram } from "../../../../../store/certificationProgram/certificationProgramSlice";

const ModalShell = ({ isShown, handleModalClose }) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const dispatch = useDispatch();
  const [selectedModalView, setSelectedModalView] = useState(
    "conference-attendance"
  );
  const [titleModal, setTitleModal] = useState("New Activity");
  const conferenceAttendance = useSelector(
    (state) => state?.conferenceAttendance?.singleConferenceAttendance
  );
  const workshopSeminar = useSelector(
    (state) => state?.workshopSeminar?.singleWorkshopSeminar
  );
  const publicSpeaking = useSelector(
    (state) => state?.publicSpeaking?.singlePublicSpeaking
  );
  const panelDiscussion = useSelector(
    (state) => state?.panelDiscussion?.singlePanelDiscussion
  );
  const professionalOrganization = useSelector(
    (state) => state?.professionalOrganization?.singleProfessionalOrganization
  );
  const committeeInvolvement = useSelector(
    (state) => state?.committeeInvolvement?.singleCommitteeInvolvement
  );
  const advancedDegree = useSelector(
    (state) => state?.advancedDegree?.singleAdvancedDegree
  );
  const certificationProgram = useSelector(
    (state) => state?.certificationProgram?.singleCertificationProgram
  );

  const renderModalView = () => {
    switch (selectedModalView) {
      case "workshop-seminars":
        return (
          <WorkshopSeminars
            userId={localUserData?.id}
            editData={workshopSeminar}
          />
        );
      case "public-speaking":
        return (
          <PublicSpeaking
            userId={localUserData?.id}
            editData={publicSpeaking}
          />
        );

      case "panel-discussions":
        return (
          <PanelDiscussions
            userId={localUserData?.id}
            editData={panelDiscussion}
          />
        );
      case "professional-organizations":
        return (
          <ProfessionalOrganizations
            userId={localUserData?.id}
            editData={professionalOrganization}
          />
        );
      case "committee-involvement":
        return (
          <CommitteeInvolvement
            userId={localUserData?.id}
            editData={committeeInvolvement}
          />
        );
      case "advanced-degrees":
        return (
          <AdvancedDegrees
            userId={localUserData?.id}
            editData={advancedDegree}
          />
        );
      case "certification-programs":
        return (
          <CertificationPrograms
            userId={localUserData?.id}
            editData={certificationProgram}
          />
        );
      default:
        return (
          <ConferenceAttendance
            userId={localUserData?.id}
            editData={conferenceAttendance}
          />
        );
    }
  };

  useEffect(() => {
    if (conferenceAttendance) {
      setSelectedModalView("conference-attendance");
    }
    if (workshopSeminar) {
      setSelectedModalView("workshop-seminars");
    }
    if (publicSpeaking) {
      setSelectedModalView("public-speaking");
    }
    if (panelDiscussion) {
      setSelectedModalView("panel-discussions");
    }
    if (professionalOrganization) {
      setSelectedModalView("professional-organizations");
    }
    if (committeeInvolvement) {
      setSelectedModalView("committee-involvement");
    }
    if (advancedDegree) {
      setSelectedModalView("advanced-degrees");
    }
    if (certificationProgram) {
      setSelectedModalView("certification-programs");
    }

    if (
      conferenceAttendance ||
      workshopSeminar ||
      publicSpeaking ||
      panelDiscussion ||
      professionalOrganization ||
      committeeInvolvement ||
      advancedDegree ||
      certificationProgram
    ) {
      setTitleModal("Update Activity");
    } else {
      setTitleModal("New Activity");
    }
  }, [
    conferenceAttendance,
    workshopSeminar,
    publicSpeaking,
    panelDiscussion,
    professionalOrganization,
    committeeInvolvement,
    advancedDegree,
    certificationProgram,
  ]);

  const handleCloseAndReset = () => {
    dispatch(resetSingleConferenceAttendance());
    dispatch(resetSingleWorkshopSeminar());
    dispatch(resetSinglePublicSpeaking());
    dispatch(resetSinglePanelDiscussion());
    dispatch(resetSingleProfessionalOrganization());
    dispatch(resetSingleCommitteeInvolvement());
    dispatch(resetSingleAdvancedDegree());
    dispatch(resetSingleCertificationProgram());
    setSelectedModalView("conference-attendance");

    handleModalClose();
  };

  return (
    <Modal
      show={isShown}
      handleClose={handleCloseAndReset}
      className="modal-shell"
      title={titleModal}
      hasFooter={false}
    >
      <div className="modal-views">
        <p>Select</p>
        <ul>
          {conferenceModalViews.map((view) => (
            <li key={`conference-view-${view.label}`}>
              <Checkbox
                type="radio"
                value={view.slug}
                onChange={() => setSelectedModalView(view.slug)}
                checked={selectedModalView === view.slug}
                className="view-item"
                disabled={
                  titleModal === "Update Activity"
                    ? selectedModalView !== view.slug
                    : false
                }
              >
                {view.label}
              </Checkbox>
            </li>
          ))}
        </ul>
      </div>
      <div className="modal-panel">{renderModalView()}</div>
    </Modal>
  );
};

export default ModalShell;
